import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Endpoints from '../../../Component/services/Endpoints';
import { styled } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text,
  }));
  
  const ENDPOINT = Endpoints.getUrls();

    const CiuseSubRows = ({row}) => {
        //   console.log(row.original)
       
           let {
               allegato,
               operatore,
               data_scadenza,
               descrizione,
               note,
               ricevute,
               statoPratica,
               storico_pratica,
               storico_operatore,
               storico_note,
               ...rest
           } = row.original;
           let consulente = rest.consulente;
           let tipo_pratica = rest.tipo_pratica;
           let assistitoObj = rest.assistito;
           let collaboratore = rest.colloboratore;

         //  console.log(row.row.row.original);
        //   console.log(rest.consulente);
       
     
       
           const RenderAllegati = () => {
               return (
       
                   <div>
               {allegato?.map((rowData, index) => ( 
                <>
                <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                       <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                       <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: 'darkorange'}} href={`${ENDPOINT}static/allegati/${rowData.path}`} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></Item>
                       <Item key={index+3} sx={{boxShadow: 'none', float: 'rigth'}}>{'Data: '}{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</Item>
                   </Box>   
                   <Divider />
                   </>
               ))}
               </div>          
               )
           }
       
           const RenderStoricoRicevute = () => {
               return (
                   <TableContainer component={Paper} sx={{background: 'none'}}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell>#</TableCell>
                           <TableCell align="right">tipo</TableCell>
                           <TableCell align="right">Url</TableCell>
                           <TableCell align="right">Creato</TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {ricevute?.map((rowData, index) => (
                           <TableRow
                             key={index}
                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                             <TableCell component="th" scope="row">{index + 1}</TableCell>
                             <TableCell align="right"><PictureAsPdfIcon sx={{color: 'red'}} /></TableCell>
                             <TableCell align="right">
                             <a style={{color: 'darkorange'}} href={`${ENDPOINT}static/pratiche/ricevute/${rowData.path} `} download
                                                  target="_blank" rel="noopener noreferrer">
                                               {rowData.path}</a></TableCell>
                             <TableCell align="right">{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>
                   </TableContainer>
                 );
           }
       
           const RenderStoricoPratica = () => {
       
       
               return (
                   
                   <div>
                   {storico_pratica?.map((rowData, index) => (
                   <>
                   <Box
                   sx={{
                     display: 'grid',
                     gap: 1,
                     gridTemplateColumns: 'repeat(3, 1fr)',
                   }}
                 >
                       <Item key={index+1} sx={{boxShadow: 'none'}}><AvTimerIcon /></Item>
                       <Item key={index+2} sx={{boxShadow: 'none'}}>{'Stato: '}{rowData.stato}</Item>
                       <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{rowData.data}</Item>
                       
                   </Box>   
                   <Divider key={index+4} />
                   </>
                     ))}
                     
                     </div>
                   
                 );
           }
       
           const RenderStoricoNote = () => {
               return (
                   <TableContainer component={Paper} sx={{background: 'none'}}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell>#</TableCell>
                           <TableCell align="right">Nota</TableCell>
                           <TableCell align="right">Data</TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {storico_note?.map((rowData, index) => (
                           <TableRow
                             key={index}
                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                             <TableCell component="th" scope="row">
                               {index + 1}
                             </TableCell>
                             <TableCell align="right">{rowData.nota}</TableCell>
                             <TableCell align="right">{rowData.data}</TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>
                   </TableContainer>
                 );
           }
       
           const RenderStoricoOperatore = () => {
               return (
                   <TableContainer component={Paper} sx={{background: 'none'}}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           <TableCell>#</TableCell>
                           <TableCell align="right">_id</TableCell>
                           <TableCell align="right">Nome</TableCell>
                           <TableCell align="right">Cognome</TableCell>
                           <TableCell align="right">Email</TableCell>
                           <TableCell align="right">Data</TableCell>
                         </TableRow>
                       </TableHead>
                       <TableBody>
                         {storico_operatore?.map((rowData, index) => (
                           <TableRow
                             key={index}
                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                             <TableCell component="th" scope="row">
                               {index + 1}
                             </TableCell>
                             <TableCell align="right">{rowData.id}</TableCell>
                             <TableCell align="right">{rowData.nome}</TableCell>
                             <TableCell align="right">{rowData.cognome}</TableCell>
                             <TableCell align="right">{rowData.email}</TableCell>
                             <TableCell align="right">{rowData.data}</TableCell>
                           </TableRow>
                         ))}
                       </TableBody>
                     </Table>
                   </TableContainer>
                 );
           }
           
           return (
               <>
               <Container maxWidth="lg">
       
                <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Consulente:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                       {`${consulente.cognome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                       {`${consulente.nome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}> Email: </small>
                       {`${consulente.email} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}> Cellulare: </small>
                       {`${consulente.cellulare} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}> Telefono: </small>
                       {`${consulente.telefono} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Button variant="contained" endIcon={<SendIcon />} size="small" disabled >
                       Invia Messaggio al consulente
                     </Button>
                   </Grid>
                 </Grid>
               </Container>
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Tipo pratica:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>descrizione: </small>
                       {`${descrizione} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>tipo pratica: </small>
                       {`${tipo_pratica} `}</Item>
                   </Grid>
                 </Grid>
               </Container>
         
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Assistito:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                       {`${assistitoObj.cognome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                       {`${assistitoObj.nome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>C.F.: </small>
                       {`${assistitoObj.codice_fiscale_assistito} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Prov.: </small>
                       {`${assistitoObj.provincia} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Comune: </small>
                       {`${assistitoObj.comune} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>Via: </small>
                       {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Tipo assistito: </small>
                       {`${assistitoObj.categoria_assistito} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>sc: </small>
                       {`${assistitoObj.stato_civile} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Email: </small>
                       {`${assistitoObj.email} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cellulare: </small>
                       {`${assistitoObj.cellulare} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Tel.: </small>
                       {`${assistitoObj.telefono} `}</Item>
                   </Grid>
                 </Grid>
               </Container>
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Collaboratore volontario:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={4}>
                     <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                       {`${collaboratore.cognome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                       {`${collaboratore.nome} `}</Item>
                   </Grid>
                   <Grid item xs={4}>
                     <Item><small style={{ color: 'rgb(131 135 140)' }}>C.F: </small>
                       {`${collaboratore.codice_fiscale} `}</Item>
                   </Grid>
                 </Grid>
               </Container>
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Stato pratica:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={4}>
                     <Item>  <small style={{ color: 'rgb(131 135 140)' }}>stato: </small>
                       {`${statoPratica} `}</Item>
                   </Grid>
       
                 </Grid>
               </Container>
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Documenti allegati:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={12}>
                     <Item>  {RenderAllegati()}</Item>
                   </Grid>
       
                 </Grid>
               </Container>

               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Storico pratica:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2} sx={{background: 'none'}}>
                   <Grid item xs={12}>
                     <Item>  {RenderStoricoPratica()}</Item>
                   </Grid>
       
                 </Grid>
               </Container>
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Storico Note:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={12}>
                     <Item>{RenderStoricoNote()}</Item>
                   </Grid>
       
                 </Grid>
               </Container>
             
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Storico Ricevuta:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2} >
                   <Grid item xs={12}>
                     <Item>{RenderStoricoRicevute()}</Item>
                   </Grid>
       
                 </Grid>
               </Container>    
       
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Storico Operatore:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={12}>
                     <Item>{RenderStoricoOperatore()}</Item>
                   </Grid>
       
                 </Grid>
               </Container>
           
               <Container maxWidth="lg">
                 <Divider className={"container"} component="div" role="presentation"
                   sx={{ marginTop: '20px', marginBottom: '20px' }}>
                   {/* any elements nested inside the role="presentation" preserve their semantics. */}
                   <Typography variant="h6">Stato Pratica attuale:</Typography>
                 </Divider>
                 <Grid container fixed="true" spacing={2}>
                   <Grid item xs={12}>
                     <Item>{`${statoPratica}`}</Item>
                   </Grid>
       
                 </Grid>
               </Container>
               </>
       
       
           );
       }
       


export default CiuseSubRows