import React from 'react';
import axios from 'axios';
import { useMemo, useState, useEffect } from 'react';
import { useFormik, FormikProvider, } from 'formik';
import * as yup from 'yup';
import {
  MRT_EditActionButtons as EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const validationSchema = yup.object({

  tipologia: yup.string('Inserire una tipologia').label('tipologia').required('tipologia è richiesta'), 
  ordine: yup.string('Inserire una ordine').label('ordine').required('ordine è richiesta'), 
});

const CreaPratica = () => {
  const [validationErrors, setValidationErrors] = useState({});

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.tipo,
          helperText: validationErrors?.tipo,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              tipo: undefined,
            }),
        },
      },
      {
        accessorKey: 'ordine',
        header: 'Ordine',
        enableEditing: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.ordine,
          helperText: validationErrors?.ordine,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              ordine: undefined,
            }),
            
          //optionally add validation checking for onBlur or onChange
        },
      },
    
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
  //call READ hook
  
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();

  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } = useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } = useDeleteUser();

  //CREATE action
  // const handleCreateUser = async (value, { values, table }) => {
    const handleCreateUser = async (value) => {
    /*
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
*/
    setValidationErrors({});
  //  await createUser(values);
    await createUser(value);
   // setCreateModalOpen(false);
    table.setCreatingRow(null); //exit creating mode
   // setCreateModalOpen(false);
    //fetchedUsers();
  };


  /*
  const handleCreateNewRow = (values) => {
     tableData.push(values);
      setTableData([...tableData]);
    };
*/
  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(row.original.id);
    }
  };

  const CreateWithMaterialUI = ({ open, rows, columns, onClose, ...props }) => {

   const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

   const [dataMenuBox, setDataMenuBox] = useState([]);

    useEffect(() => {
        const doFetch = async () => {
              
            let nextValue = [];
            const response = await fetch(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_box`);
            const tipopraticaBox = await response.json();
            const highest = tipopraticaBox.sort((a, b) => b.ordine - a.ordine)[0];

            //easy increment
            nextValue = [{ ordine: highest.ordine+1}];
            console.log(nextValue);
            setDataMenuBox(nextValue);           
            };
        
        doFetch();

        }, []);

  const formik = useFormik({
    initialValues: {
      tipologia: '',
      ordine: '',
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));

      const values2 = {
        ordine: values.ordine,
        tipo: values.tipologia
      };
      handleCreateUser(values2)
      onClose()
      /*
      axios.post(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_box/create`, {
        ordine: values.ordine,
        tipo: values.tipologia
      })
      .then(function (response) {
        console.log(response);
        onClose()
        fetchedUsers();
        
      })
      .catch(function (error) {
        console.log(error);
      });
*/
    },
  });


  return (
    <Dialog
    open={open}
    onClose={onClose}
    fullWidth={true}
    scroll={scroll}
  >
    <DialogTitle>Crea nuovo tipo pratica</DialogTitle>
    <DialogContent>
    <div>
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
      <Box  sx={{ flexGrow: 1, p: 2 }}>

      <Grid  container spacing={1}>

      <Grid item  xs={12} sm={12} md={12}>
      <TextField
      fullWidth
      variant="outlined"
      name="ordine"
      id="ordine"
      select
      label="Ordine"
      value={formik.values.ordine}
      //sx={{overflowY: "scroll"}}
      onChange={formik.handleChange}
      error={
        formik.touched.ordine &&
        Boolean(formik.errors.ordine)
      }
      helperText={
        formik.touched.ordine && formik.errors.ordine
      }
    >
      {dataMenuBox.map((option) => (
        <MenuItem key={option.ordine} value={option.ordine}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item>{option.ordine}</Item>
          </Grid>
        </Grid>   
        </MenuItem>
      ))}
    </TextField>
    </Grid> 
      <Grid item  xs={12} sm={12} md={12}>
          <TextField
          fullWidth
          id="tipologia"
          name="tipologia"
          label="tipologia"
          value={formik.values.tipologia}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.tipologia && Boolean(formik.errors.tipologia)}
          helperText={formik.touched.tipologia && formik.errors.tipologia}
        />
      </Grid>

      </Grid> 
     
      </Box>

      {/* 
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
*/}
        <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button type="submit">Crea nuova</Button>
      </DialogActions>
      </form>
      </FormikProvider>
    </div>
    </DialogContent>
    {/* 
      <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="submit">Subscribe</Button>
    </DialogActions>
  */}

  </Dialog>
  );
};

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Modifica Tipo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table, row }) => (

      <>
      <Button
      onClick={() => setCreateModalOpen(true)}
    >
    
      Aggiungi Tipo
  </Button> 


    <CreateWithMaterialUI
        rows={row}
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
       // onSubmit={handleCreateNewRow} 
       />
 
       </>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  return <MaterialReactTable table={table} />;
};

//CREATE hook (post new user to api)
function useCreateUser(value) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      axios.post(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_box/create`, user)
      .then((res) => res.data)
      .then(queryClient.refetchQueries())
    },
    //client side optimistic update
   
    onMutate: (newUserInfo) => {

   //   queryClient.setQueryData(['users']);
   //   queryClient.refetchQueries();
/*
      queryClient.setQueryData(['users'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
*/
    },
   
  onSuccess: () => {
    queryClient.invalidateQueries({queryKey: ["users"]});
  },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: () =>
      axios
        .get('https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_box')
        .then((res) => res.data),
        refetchOnWindowFocus: false,
  });

}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <CreaPratica />
  </QueryClientProvider>
);

export default ExampleWithProviders;

const validateRequired = (value) => !!value.length;

function validateUser(user) {
  return {
    ordine: !validateRequired(user.ordine)
      ? 'First Name is Required'
      : '',
    tipo: !validateRequired(user.tipo) ? 'Last Name is Required' : '',
  };
}
