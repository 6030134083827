import React, {useState} from 'react';
import {
  Stack,
} from "@mui/material";
import { Outlet,  Navigate, useLocation } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function getNumber(location) {
  const pathname = location.split("/")[3];
  if (!pathname) return 0;
  else {
    switch (pathname) {
      case "vedi":
        return 0;
      case "aggiungi":
        return 1
      default:
        return 0;
    }
  }
}

const AdminUsersPages = props => {

  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(getNumber(location?.pathname));
//  const [selectedTab, setSelectedTab] = useState(0);

//  console.log("usersdmin")
//  console.log(getNumber(location?.pathname));

 //   const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
      setSelectedTab(newValue);
  };

  return (
    <>

    <Box sx={{ display:"flex", justifyContent:"center", width:"100%" }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: { xs: 500, sm: 880, md: '100%', lg: '100%'  } }} >
      <Tabs 
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        textColor="secondary"
        indicatorColor="secondary"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
        >
                    
      <Tab component="a" label='Vedi'/>
      <Tab label='Aggiungi'/>
      </Tabs>
      </Box>
      {selectedTab === 0 && <Navigate to={"vedi"} />}
      {selectedTab === 1 && <Navigate to={"aggiungi"} />}
      </Box>
    <Stack gap={"30px"}>
    {/* 
      <Typography variant="h6" align={"center"}>
        Operatore Area Page
      </Typography>
*/}

      <Stack>
        <Outlet />
      </Stack>
    </Stack>
    </>
  );
};

export default AdminUsersPages;
