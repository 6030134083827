import http from "./http-common";

import Endpoints from "./Endpoints";

const ENDPOINT = Endpoints.getUrls();

class UploadFilesService {

  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "mode": 'no-cors'
      },
      onUploadProgress,
    });
  }

  getFiles(url) {
  //    return <a href={`http://localhost:5001/static/allegati/${allegato} `}></a>
  //  return `http://localhost:5001/static/allegati/${url}`;

  let {message, path} = url;

  let data = {
      message: message,
      path: `${ENDPOINT}static/allegati/${path}`
  }


  return {data, path};
  }

}

export default new UploadFilesService();
