import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { useMemo, useState, useEffect } from 'react';
//import TableAttiveContenitore from './TableAttiveContenitore';
import { Box, IconButton, Tooltip, Button } from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//import BozzeSub from './BozzeSub';
//import Endpoints from '../../../Component/services/Endpoints';
//import FileUpload from '../../File_Upload_Component/file-upload.component'

//const ENDPOINT = Endpoints.getUrls();



const TableBozzeContenitore = ({data, columns, 
  renderRowSubComponent, 
  praticaSelezionata, 
  deletePratica, 
  isLoading,  
  isError, 
  isRefetching,
  setColumnFiltersFunc,
  setGlobalFilterFunc,
  setSortingFunc,
  setPaginationFunc,
  rowCount,
  handleExportRows,
  handleExportData
}) => {



const table = useMaterialReactTable({
  columns,
  data,
  enableExpandAll: false, //disable expand all button
  enableExpanding: true,
  enableRowActions: true,
  initialState: { 
    showColumnFilters: true,
    columnVisibility: { _id: false }, //hide _id column by default
   // pagination: { pageSize: 25, pageIndex: 2 }
},
state: {
  isLoading,
  showAlertBanner: isError,
  showProgressBars: isRefetching,
},
onColumnFiltersChange:()=> setColumnFiltersFunc,
onGlobalFilterChange: ()=> setGlobalFilterFunc,
onPaginationChange: ()=> setPaginationFunc,
onSortingChange: ()=> setSortingFunc,
rowCount,
positionToolbarAlertBanner: 'bottom',
positionActionsColumn: 'last',
  muiDetailPanelProps: () => ({
    sx: (theme) => ({
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,210,244,0.1)'
          : 'rgba(0,0,0,0.1)',
    }),
  }),

     //custom expand button rotation
     muiExpandButtonProps: ({ row, table }) => ({
      children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
      onClick: () =>  {table.setExpanded({ [row.id]: !row.getIsExpanded() });  praticaSelezionata(row.original._id)}, 
      //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },

    }),
    

    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
      <Tooltip title="Elimina bozza">
      <IconButton
        color="error"
        onClick={() => deletePratica(row.original._id)}
      >
       <DeleteIcon />
      </IconButton>
      </Tooltip>
    </Box>
    ),
/*
    renderDetailPanel: ({ row }) => (
      
      <AttiveSub row={row} praticaId={row.original._id}/>

    ),
 */   
     renderDetailPanel: ({ row }) => renderRowSubComponent( row ),
     renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
     
});

  return <MaterialReactTable 
  table={table} />;

};

export default TableBozzeContenitore;
