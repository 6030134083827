import React, {useState} from 'react';
import {
  Stack,
} from "@mui/material";
import { Outlet,  Navigate, useLocation, useSearchParams } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';




const ConsulentePages = props => {

  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = React.useState(
    searchParams.get("ids")
  );
  const [selectedTab, setSelectedTab] = useState(getNumber(location?.pathname));
//  const [selectedTab, setSelectedTab] = useState(0);

    console.log("consulente")
    console.log(getNumber(location?.pathname));

    console.log(query);

 // const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function getNumber(location) {
      console.log(location)
      console.log(searchParams.get('ids'));
    
      const pathname = location.split("/")[3];
      console.log(pathname)
      if (!pathname) return 0;
      else {
        switch (pathname) {
          case "elenco_pratiche":
            return 0;
          case "nuova_pratica":
            return 1;
          case "inviate":
              return 2;
              case "attive":
                return 3;
                  case "sospese":
                return 4;
          case "chiuse":
                return 5;
        case "annullate":
                return 6  
              case "bozze":
                return 7           
          default:
            return 0;
        }
        /*
      <Tab label='Nuova'/>
       <Tab label='Inviate'/>
      <Tab label='Attive'/>
       <Tab label='Sospese'/>
        <Tab label='Chiuse'/>
       <Tab label='Annullate'/>
      <Tab label='Bozze'/>
        <Tab label='Test'/>
        */
    
      }
    }


  return (
    <>

    <Box sx={{ display:"flex", justifyContent:"center", width:"100%" }} >
    {selectedTab}{query}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: { xs: 500, sm: 880, md: '100%', lg: '100%'  } }} >
      <Tabs 
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
        >
                    
      <Tab component="a" label='Elenco'/>
      <Tab label='Nuova'/>
       <Tab label='Inviate'/>
      <Tab label='Attive'/>
       <Tab label='Sospese'/>
        <Tab label='Chiuse'/>
       <Tab label='Annullate'/>
      <Tab label='Bozze'/>

       {/* <Tab label='Sospese'/>
      <Tab label='Annullate'/>
      <Tab label='Chiuse' /> */}
      </Tabs>
      </Box>
      {selectedTab  === 0 ? <Navigate to="elenco_pratiche" /> : null}
      {selectedTab  === 1 ? <Navigate to={`nuova_pratica?ids=${query}`} /> : null}
      {selectedTab  === 2 ? <Navigate to="inviate" /> : null}
      {selectedTab  === 3 ? <Navigate to="attive" /> : null}
      {selectedTab  === 4 ? <Navigate to="sospese" /> : null}
      {selectedTab  === 5 ? <Navigate to="Chiuse" /> : null}
      {selectedTab  === 6 ? <Navigate to="annullate" /> : null}
      {selectedTab  === 7 ? <Navigate to="bozze" /> : null}

      
      {/* 
      
      {selectedTab === 0 && <Navigate to="elenco_pratiche" />}
      {selectedTab === 1 && <Navigate to="nuova_pratica" />}
      {selectedTab === 2 && <Navigate to="test_pratica" />}
*/}
      </Box>
    <Stack gap={"30px"}>
    {/* 
      <Typography variant="h6" align={"center"}>
        Operatore Area Page
      </Typography>
*/}

      <Stack>
        <Outlet />
      </Stack>
    </Stack>
    </>
  );
};

export default ConsulentePages;
