import React, {useState} from 'react';
import {
  Stack,
} from "@mui/material";
import { Outlet,  Navigate, useLocation } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


function getNumber(location) {
  const pathname = location.split("/")[3];
  if (!pathname) return 0;
  else {
    switch (pathname) {
      case "inviate":
        return 0;
      case "attive":
        return 1
      case "attive_generali":
        return 2
      case "sospese":
        return 3
      case "annullate":
        return 4
      case "chiuse":
      return 5

      default:
        return 0;
    }
  }
}

const OperatorePages = props => {

  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(getNumber(location?.pathname));
//  const [selectedTab, setSelectedTab] = useState(0);

console.log("etts")
console.log(getNumber(location?.pathname));

 //   const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    
 /*  const handleChange = (event, newValue) => {
    setSelectedTab(newValue); 

history.push(
  `/${
    newValue === 1
      ? "attive"
      : newValue === 2
      ? "inviate"
      : newValue === 3
      ? "chiuse"
      : "attive"
    }`
  );
   
  };
  */

// const location = useLocation();
// console.log(location);


  return (
    <>

    <Box sx={{ display:"flex", justifyContent:"center", width:"100%" }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: { xs: 500, sm: 880, md: '100%', lg: '100%'  } }} >
      <Tabs 
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
        >
                    
      <Tab component="a" label='Inviate'/>
      <Tab label='Attive'/>
      <Tab label='Generali'/>
      <Tab label='Sospese'/>
      <Tab label='Annullate'/>
      <Tab label='Chiuse' />
      </Tabs>
      </Box>
      {selectedTab === 0 && <Navigate to={"inviate"} />}
      {selectedTab === 1 && <Navigate to={"attive"} />}
      {selectedTab === 2 && <Navigate to={"attive_generali"} />}
      {selectedTab === 3 && <Navigate to={"sospese"} />}
      {selectedTab === 4 && <Navigate to={"annullate"} />}
      {selectedTab === 5 && <Navigate to={"chiuse"} />}
      </Box>
    <Stack gap={"30px"}>
    {/* 
      <Typography variant="h6" align={"center"}>
        Operatore Area Page
      </Typography>
*/}

      <Stack>
        <Outlet />
      </Stack>
    </Stack>
    </>
  );
};

export default OperatorePages;
