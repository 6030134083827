const FormElements = {
    "form": {
        "sections": [
            {
                "section_title": "Add user",
                "fields": [
                    {
                        "name": "nome",
                        "label": "Nome",
                        "hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name": "cognome",
                        "label": "Cognome",
                        "hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                         "name": "codice_fiscale",
                         "label": "C.F.",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                         "name": "partita_iva",
                         "label": "P.I.",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"ragione_sociale",
                        "label": "Ragione Sociale",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"cellulare",
                        "label": "Cellulare",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"telefono",
                        "label": "Telefono",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"consenso",
                        "label": "Consenso",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"creato",
                        "label": "Creato il",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"email",
                        "label": "Email",
 						"hidden": false,
                        "required": true,
                        "data_type":"email",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },

                    {
                        "name":"password",
                        "label": "Password",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"pec",
                        "label": "Pec",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"stato",
                        "label": "Stato",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"ritratto",
                        "label": "Foto",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    
                ]
            }
        ],
        "documenti": [
            {
                "section_title": "documenti",
                "field": [
                    {
                        "name":"documento",
                        "label": "Documento",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"mandato",
                        "label": "Mandato",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    }
                ]
            }
        ],
        "address": [
            {
                "section_title": "indirizzo",
                "fields": [
                    {
                        "name":"via",
                        "label": "Via",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"citta",
                         "label": "Città",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"comune",
                        "label": "Comune",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"cap",
                        "label": "Cap",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"provincia",
                        "label": "Provincia (Sigla)",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name":"regione",
                        "label": "Regione",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
  
                    {
                        "name":"localita_esercizio",
                        "label": "Esercizio Comune",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                ]
            }
        ],
        "roles": [
            {
                "section_title": "roles",
                "field": [
                    "admin",
                    "consulente",
                    "operatore",
                    "teleconsul",
                    "namirial",
                    "regionale"
                ]
            }
        ],
        "collaboratore": [
            {
                "section_title": "collaboratore",
                "fields": [
                    {
                        "name": "nome",
                        "label": "Nome",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name": "cognome",
                        "label": "cognome",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    },
                    {
                        "name": "codice_fiscale"
                        ,
                        "label": "Codice Fiscale",
 						"hidden": false,
                        "required": true,
                        "data_type":"Text",
                        "html_element": "TextField",
                        "error": false,
                        "variant": "outlined"
                    }
                ]
            }
        ]
    }
}
	  
export default FormElements 