import React, {useState, useEffect, useMemo} from 'react';
import axios from "axios";
//import { useStore } from '../../Store';  
import Swal from 'sweetalert2';
import Endpoints from "../../../Component/services/Endpoints";
//import logo from '../../logo.svg';
import socketIOClient from "socket.io-client";
import AllegatiUpload from '../../Allegati_Upload_Component/Allegati-upload.component'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableBozzeContenitore from './TableBozzeContenitore';
import FormMessageDialog from './FormMessageDialog';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const ENDPOINTALLEGATI = Endpoints.getAllegatiUrls();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text,
}));

const ENDPOINT = Endpoints.getUrls();

const BozzeSub = ({row}) => {

//  const [store] = useStore();
    const [data, setData] = useState([])

    const [isActiveAccount , setIsActiveAccount] = useState("");
    //FileInfos anche se da warning che non è usato non è vero
    const [fileInfos, setFileInfos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [allegati, setAllegati] = useState([]);
    const [allegato, setAllegato]= useState([]);

    const [note, setNote] = useState('');
    const [selectedIdPratica, setSelectedIdPratica] = useState('');

    const [isVisible, setIsVisible] = useState(false);

     //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //from old component
    const [currentLoggedUser, setCurrentLoggedUser] = useState({});
    const [collaboratoreVolontario, setCollaboratoreVolontario] = useState({});
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });
    const [emptyAllegato, setEmptyAllegato] = useState(true);
    const [newAllegatoInfo, setNewAllegatoInfo] = useState({
      allegatoFile: []
  });
  
    const [openDialog, setOpenDialog] = useState(false);
//  const [consulenteAttivo, setconsulenteAttivo] = useState(false); 
    const consulenteAttivo = JSON.parse(localStorage.getItem('currentUser'));
    // const {_id, cognome, nome, email, role} = operatoreClient;
   //  setconsulenteAttivo(operatoreClient);

   const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  

    const handleExportRows = (rows) => {
      const rowData = rows.map((row) => row.original);
      let ArrayCsv = [];
      for (let index = 0; index < rowData.length; index++) {
        
       let ele = {
          "_id": rowData[index]._id,
          "consulente": JSON.stringify(rowData[index].consulente),
          "operatore": JSON.stringify(rowData[index].operatore),
          "statoPratica": rowData[index].statoPratica,
          "allegato": JSON.stringify(rowData[index].allegato),
          "tipo_pratica": rowData[index].tipo_pratica,
          "descrizione": rowData[index].descrizione,
          "assistito": JSON.stringify(rowData[index].assistito),
          "colloboratore": JSON.stringify(rowData[index].colloboratore),
          "data_scadenza": rowData[index].data_scadenza,
          "note": rowData[index].note,
          "storico_pratica": JSON.stringify(rowData[index].storico_pratica),
          "ricevute": JSON.stringify(rowData[index].ricevute),
          "storico_note": JSON.stringify(rowData[index].storico_note),
          "pratica_numero": rowData[index].pratica_numero,
      }
      ArrayCsv.push(ele)
      }
      const csv = generateCsv(csvConfig)(ArrayCsv);
      download(csvConfig)(csv);
    };

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(data);
      let ArrayCsvs = [];
      for (let index = 0; index < csv.length; index++) {
        
       let ele = {
         /* "_id": data[index]._id, */
          "consulente": JSON.stringify(data[index].consulente),
          "operatore": JSON.stringify(data[index].operatore),
          "statoPratica": data[index].statoPratica,
          "allegato": JSON.stringify(data[index].allegato),
          "tipo_pratica": data[index].tipo_pratica,
          "descrizione": data[index].descrizione,
          "assistito": JSON.stringify(data[index].assistito),
          "colloboratore": JSON.stringify(data[index].colloboratore),
          "data_scadenza": data[index].data_scadenza,
          "note": data[index].note,
          "storico_pratica": JSON.stringify(data[index].storico_pratica),
          "ricevute": JSON.stringify(data[index].ricevute),
          "storico_note": JSON.stringify(data[index].storico_note),
          "pratica_numero": data[index].pratica_numero,
      }
      ArrayCsvs.push(ele)
      }
      download(csvConfig)(ArrayCsvs);
    };
  

   function setColumnFiltersFunc(val){
    setColumnFilters(val);
   }
   function setGlobalFilterFunc(val){
    setGlobalFilter(val);
   }
   function setSortingFunc(val){
    setSorting(val);
   }

   function setPaginationFunc(val){
    setPagination(val);
   }

   function praticaSelezionata( praticaID ) {
    setSelectedIdPratica(praticaID)
    console.log("praticaSelezionata", praticaID)
  }

  

  function deletePratica( rowID ) {
   
    var idPratica = rowID;
    const adminClient =JSON.parse(localStorage.getItem('currentUser'));
  
  if(adminClient.role[0] === 'ROLE_CONSULENTE'){
    Swal.fire({
      title: 'Vuoi davvero eliminare?',
      icon: 'danger',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI, elimina!',
      footer: `<b>Consulente<b/>`,
    }).then((result) => {
      if (result.isConfirmed) {
  //inizio conferma seleziona
     // qui elimino la pratica
      fetch(`${ENDPOINT}pratiche/eliminazione/action/id?_id=${idPratica}`, {
        headers: {
          'Content-Type': 'application/json',
        },
       
      })
        .then(res => res.json())
        .then(data => {
          if(data.success){

            let updTbl = {
              praticaId: idPratica
            }
         
            renewTableAfterDelete(updTbl);
  
            Swal.fire(
              'Eliminita!',
              data.success,
              'Grazie'
            )
  
         }
         if(data.error){
          Swal.fire(
            'Error!',
            data.error,
            'Grazie'
          )
            }
  
        })
        .catch(error => {
        // enter your logic for when there is an error (ex. error toast)
        console.log(error)
        });
  
        //fine conferma seleziona
      }
    })
  
  } else {
    Swal.fire(
      'Non hai i privilegi per eseguire questa azione',
      'Grazie'
    )
   
  }
  }

  const renewTableAfterDelete = (val) => {
    setData((data) => data.filter(({_id}) => _id !== val.praticaId));
}
  
    // fetch pratiche in lavorazione by operatore id
    //if you want to avoid useEffect, look at the React Query example instead
    useEffect(() => {
       
           const fetchData = async () => {
             if (!data.length) {
               setIsLoading(true);
             } else {
               setIsRefetching(true);
             }
           
           const op = JSON.parse(localStorage.getItem('currentUser'));
           const id = op._id;
           setCurrentLoggedUser(op);
       
            const url = new URL(`${ENDPOINT}pratiche/consulente/bozza/id?_id=${id}`,
               process.env.NODE_ENV === 'production'
                 ? 'https://www.material-react-table.com'
                 : 'http://localhost:3000',
             );
             url.searchParams.set(
               'start',
               `${pagination.pageIndex * pagination.pageSize}`,
             );
             url.searchParams.set('size', `${pagination.pageSize}`);
             url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
             url.searchParams.set('globalFilter', globalFilter ?? '');
             url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
       
             try {
               const response = await fetch(url.href);
               const json = await response.json();
               setData(json);
               setRowCount(response.headers.get('X-Total-Count'));

               const checkValidity = async ()=> {
                //Check collaboratore e se l'account è attivo
                let oPobject = JSON.parse(localStorage.getItem('currentUser'));
    
                const requestMenuItems = {
                  method: 'POST',
                  async: true,
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ ids: oPobject._id })
                    };
                  
            
                fetch(`${ENDPOINT}consulente/checkcollaboratore/`, requestMenuItems)
                .then(response => response.json())
                .then(data => setCollaboratoreVolontario(data.collaboratore));
                const request = {
                  method: 'GET',
                  async:true,
                  headers: { 'Content-Type': 'application/json' }
                    };
            
                  fetch(`${ENDPOINT}users/checkstato/id?_id=${oPobject._id}`, request)
                  .then(response => response.json())
                  .then(data => setIsActiveAccount(data.stato)
                  );
                  }
                  checkValidity();


             } catch (error) {
               setIsError(true);
               console.error(error);
               return;
             }
             setIsError(false);
             setIsLoading(false);
             setIsRefetching(false);
           };
           fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [
           columnFilters,
           globalFilter,
           pagination.pageIndex,
           pagination.pageSize,
           sorting,
    ]);
     
    const columns = useMemo(
           () => [
             {
               accessorKey: '_id',
               header: '_id',
             },
             {
              accessorKey: 'pratica_numero',
              header: 'id',
              minSize: 20, //min size enforced during resizing
              maxSize: 80, //max size enforced during resizing
              size: 20, //medium column
            },
             //column definitions...
             {
               accessorKey: 'descrizione',
               header: 'Descrizione',
             },
             {
               accessorKey: 'assistito.regione',
               header: 'Regione',
             },
            
             {
              accessorFn: (row) => `${row.assistito.cognome} ${row.assistito.nome}`,
              id: 'assistito.cognome',
               header: 'Assistito',
             },
        /*  */
             {
               //accessorFn function that combines multiple data together
             accessorFn: (row) => (row.assistito.codice_fiscale_assistito ? row.assistito.codice_fiscale_assistito : undefined),
             id: 'cf_assistito',
             header: 'CF.Assistito',
             },
     
             {
               //accessorFn function that combines multiple data together
             accessorFn: (row) => `${row.consulente.nome} ${row.consulente.cognome}`,
             id: 'consulente',
             header: 'Consulente',
             },
       
             {
               accessorKey: 'statoPratica',
               header: 'Stato',
             },
             {
               accessorKey: 'data_scadenza',
               header: 'Scade il',
             },
             
                   /*
             {
               accessorKey: d => `${d.storico_pratica[d.storico_pratica.length - 1].data}`,
               header: 'Inviata il',
             },
             */
             //end
           ],
           [],
    );
      

    const renderRowSubComponent = (row) => {
      //console.log(row.original)

    const eliminaAllegatoEsistente = (path, index, id, riga) => {

      alert(path, index, id);
    // console.log(data);
    // console.log(data[id]);
    // console.log(allegato);
    // console.log(fileInfos)


    var arraySlice = JSON.parse(JSON.stringify(data));
    arraySlice[parseInt(id)].allegato.splice(0, 1);;
    //console.log(JSON.stringify(arraySlice,null, 4));

    setData(arraySlice)

      }


    const handleAllegato = () => {
    setIsVisible(true);
    let selectedFile = newAllegatoInfo.allegatoFile;
    //console.log(element)


    console.log('Test Allegato');

    const data = new FormData()
    for (var x = 0; x < selectedFile.length; x++) {
      if(selectedFile[x].type !== 'application/pdf')
    { alert('PUOI ALLEGARE SOLTANTO FILE PDF! Prego rimuovere tutti i file non pdf e ripetere l\'operazione');
      return;
    } else   {
      console.log('Test Files');
      data.append('allCollection', selectedFile[x]);

    const headers = {"Content-Type": "multipart/form-data"};

    // ATTENZIONE A DOVE CARICA
    axios.post(`${ENDPOINT}allegati`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: ProgressEvent => {
        setProgress((ProgressEvent.loaded / ProgressEvent.total * 100))
        },
    }).then(response => { // then print response status
      console.log(response);
      setFileInfos(response.data)
    //  setFileInfos(response.data);

      let allegatiArray = [];

        for(let i =0; i< response.data.length; i++){
          allegatiArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString()
          })
        }
        setAllegati(allegatiArray);
        setEmptyAllegato(false);

    })
    }

    //********************* */
    }
    }


    let {
        allegato,
        operatore,
        data_scadenza,
        descrizione,
        note,
        ricevute,
        statoPratica,
        storico_pratica,
        storico_operatore,
        storico_note,
        ...rest
    } = row.original;
    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let pratica_id = rest._id;
    let idRiga = row.id;
    let indexRiga = row.index;



   // let statiPossibili = ['Lavorazione', 'Chiusa', 'Sospesa', 'Annullata'];
  //  console.log(row.row.row.original);
 //   console.log(rest.consulente);

    function notificaAlConsulente(data) {
/*
        const notifyObj = {
        emailto: data.consulente.email,
        idpratica: data._id,
        descrizione: data.descrizione,
        stato: data.statoPratica,
        assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
        operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
        contatti: `${data.operatore[data.operatore.length - 1].email}`
        }

        
        //notifico con mail al consulente
        axios.post(`${ENDPOINT}consulente/notifica/action`, notifyObj)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
        */ 
    
    }

    const renewTable = (val) => {
            setData((data) => data.filter(({_id}) => _id !== val.praticaId));

    }

    const handleSubmit = (event) => {
      event.preventDefault();
      const stato = event.nativeEvent.submitter.name;

      if(stato === 'inviata'){
        if(allegati.length >0){
          setEmptyAllegato(true);
        }  
      // setStatoPratica(event.nativeEvent.submitter.name);
      }

      if(stato === 'Bozza'){
            if(allegati.length >0){
              setEmptyAllegato(true);             
            }
            /*
            setStatoPratica({
                statoPratica: event.nativeEvent.submitter.name
              },() => {
                handleSubmit2();
              });
            */
            }
        
           

        let aggiornaObj = {
            id: selectedIdPratica,
            statoPratica: stato,
            allegato: allegati,
            note: note,
            storico_pratica: {
                stato: stato,
                data: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
            }
        }
        console.log(aggiornaObj);

        const headers = {
            "Content-Type": "application/json",
        }

        axios.post(`${ENDPOINT}pratiche/consulente/cambiostato/`, aggiornaObj, {
            headers: headers,
        })
            .then(response => { // then print response status
                console.log(response);

                fetch(`${ENDPOINT}pratiche/inserita/id?_id=${aggiornaObj.id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(response => response.json())
                    .then(data => notificaAlConsulente(data));


                Swal.fire({
                    title: 'Cambio stato',
                    text: `Hai cambiato questa pratica in: ${stato}`,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK!',
                    footer: `<b>Consulente<b/>`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const socket = socketIOClient(ENDPOINT);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);
                    } else {
                        const socket = socketIOClient(ENDPOINT);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);

                    }
                })


            })

        //fine
    }

    const RenderAllegati = () => {
        return (

            <div>
        {allegato?.map((rowData, index, row) => ( 

          <>
            <Stack key={index}
                direction="row"
                sx={{ width: '100%' }}               
                spacing={12}
            >
                <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: 'darkorange'}} href={`${ENDPOINT}static/allegati/${rowData.path}`} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></Item>
                <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</Item>
                <Item key={index+4} sx={{boxShadow: 'none'}}><button type="button" onClick={()=>eliminaAllegatoEsistente(rowData.path, index, idRiga, indexRiga)}>Elimina</button></Item>
            </Stack> 
            <Divider />
            </>
        ))}
        </div>          
        )
    }

    // funzione per gestire l'array delle ricuvute
    const updateUploadedFiles = (files) =>
   // setNewRicevutaInfo({...newRicevutaInfo, ricevutaFile: files});
    setNewAllegatoInfo({...newAllegatoInfo, allegatoFile: files})
  
    const renderBottoni = () => {
  
      if(isActiveAccount === 'Off'){
    
        return <div style={{color: 'red', textAlign: 'center', padding: '20px', marginTop: '25px'}}><h4>Account sospeso: prego contattare CDL per l'abilitazione. Grazie</h4></div>
    
      } else {
    
        if(!collaboratoreVolontario.codice_fiscale){
          return( <div>Non puoi procedere: regolarizzare la posizione Del collaboratore volontario  </div> )
    
        } else if (collaboratoreVolontario.codice_fiscale && !emptyAllegato) {
          return(
          <div>
          <Button variant="contained" color="success" type="submit" name="Bozza" style={{ float: 'left' }}>
            SALVA BOZZA
          </Button>
          <Button variant="contained" color="primary" type="submit" name="inviata" style={{ float: 'right' }}>
          INVIA Pratica
          </Button>
            </div>
          )
        } else if (collaboratoreVolontario.codice_fiscale && emptyAllegato) {
          return(
            <Button variant="contained" color="success" type="submit" name="Bozza" style={{ float: 'left' }}>
            SALVA BOZZA
          </Button>
          )
          }
    
      }
    
    }


    const eliminaAllegato = (path, index) => {

      alert(path, index);
      console.log(allegato);
      console.log(fileInfos)
  
      setAllegato([
          ...allegato.slice(0, index),
          ...allegato.slice(index + 1)
      ]);
  
      setFileInfos([
          ...fileInfos.slice(0, index),
          ...fileInfos.slice(index + 1)
      ]);
  
      axios({
          method: 'get',
          url: `${ENDPOINT}pratica/delete/allegato/path?path=${path}`
      })
          .then(function (response) {
            setProgress(0);
              console.log(response);
          });
      }



    return (
        <>
            <Container maxWidth="lg">

           
           <FormMessageDialog 
           sendId = {pratica_id} 
           descrizione={row.original.descrizione} 
           target = {consulente} 
           consulenteAttivo={consulenteAttivo} 
           openDialog={openDialog} 
           setOpenDialog={setOpenDialog} />

           


                <Divider className={"container"} component="div" role="presentation"
                    sx={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Typography variant="h6">Consulente:</Typography>
                </Divider>
                <Grid container fixed="true" spacing={2}>
                    <Grid item xs={4}>
                        <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                            {`${consulente.cognome} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                            {`${consulente.nome} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Email: </small>
                            {`${consulente.email} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Cellulare: </small>
                            {`${consulente.cellulare} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Telefono: </small>
                            {`${consulente.telefono} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" endIcon={<SendIcon />} size="small" onClick={() => setOpenDialog(true)} >
                        Invia Messaggio al consulente
                      </Button>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="lg">
            <Divider className={"container"} component="div" role="presentation"
            sx={{marginTop: '20px', marginBottom: '20px'}}>

       <Typography variant="h6">Tipo pratica:</Typography>
          </Divider>
            <Grid container  fixed="true" spacing={2}>
            <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>descrizione: </small>
                {`${descrizione} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>tipo pratica: </small>
                {`${tipo_pratica} `}</Item>
            </Grid>
            </Grid>
            </Container>


            <Container maxWidth="lg">
            <Divider className={"container"} component="div" role="presentation"
                sx={{ marginTop: '20px', marginBottom: '20px' }}>
                {/* any elements nested inside the role="presentation" preserve their semantics. */}
                <Typography variant="h6">Assistito:</Typography>
            </Divider>
            <Grid container fixed="true" spacing={2}>
                <Grid item xs={4}>
                    <Item> <small style={{color: 'rgb(131 135 140)'}}>Cognome: </small>
                    {`${assistitoObj.cognome} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Nome: </small>
                    {`${assistitoObj.nome} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item> <small style={{color: 'rgb(131 135 140)'}}>C.F.: </small>
                    {`${assistitoObj.codice_fiscale_assistito} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Prov.: </small>
                    {`${assistitoObj.provincia} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Comune: </small>
                    {`${assistitoObj.comune} `}</Item>
                </Grid>
                <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>Via: </small>
                {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Tipo assistito: </small>
                {`${assistitoObj.categoria_assistito} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>sc: </small>
                {`${assistitoObj.stato_civile} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Email: </small>
                {`${assistitoObj.email} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>Cellulare: </small>
                {`${assistitoObj.cellulare} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Tel.: </small>
                {`${assistitoObj.telefono} `}</Item>
            </Grid>
            </Grid>
           </Container>

        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
    <Typography variant="h6">Collaboratore volontario:</Typography>
    </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={4}>
            <Item> <small style={{color: 'rgb(131 135 140)'}}>Cognome: </small>
            {`${collaboratore.cognome} `}</Item>
        </Grid>
        <Grid item xs={4}>
            <Item><small style={{color: 'rgb(131 135 140)'}}>Nome: </small>
            {`${collaboratore.nome} `}</Item>
        </Grid>
        <Grid item xs={4}>
            <Item><small style={{color: 'rgb(131 135 140)'}}>C.F: </small>
            {`${collaboratore.codice_fiscale} `}</Item>
        </Grid>
        </Grid>
        </Container>

        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
        {/* any elements nested inside the role="presentation" preserve their semantics. */}
        <Typography variant="h6">Stato Pratica attuale:</Typography>
        </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>{`${statoPratica}`}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
        <Typography variant="h6">Documenti precedentemente allegati:</Typography>
        </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>  {RenderAllegati()}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container>
        <Divider className={"container"} component="div" role="presentation"  sx={{marginTop: '20px', marginBottom: '20px'}}>
        <Typography variant="h5">Allegati</Typography>
        </Divider>

        

          <Stack sx={{ width: '100%',paddingBottom: '8px' }} spacing={2}>
          <Alert variant="outlined" severity="info">
          {`1) SELEZIONA I DOCUMENTI IN PDF DA ALLEGARE ALLA PRATICA CLICCANDO SU "SELEZIONA FILES`}
          </Alert>

        </Stack>

        <Stack sx={{ width: '100%',paddingBottom: '8px' }} spacing={2}>
        
        <AllegatiUpload       
        accept=".pdf"
        label="Area caricamento ricevute"
        multiple
        updateFilesCb={updateUploadedFiles}
        />
        </Stack>
                {isVisible ?
                  <>                 
                             <Grid container  fixed="true" spacing={12}>
                                <Grid item xs={12}>
                                  <Item>       
                                       
                                        {fileInfos.map((el, index) => ( 
                                
                                          <>
                                            <Stack key={index}
                                                direction="row"
                                                sx={{ width: '100%' }}               
                                                spacing={12}
                                            >
                                            <Item key={index+4} sx={{boxShadow: 'none'}}>
                                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <CircularProgress variant="determinate" value={progress} />
                                                  <Box
                                                    sx={{
                                                      top: 0,
                                                      left: 0,
                                                      bottom: 0,
                                                      right: 0,
                                                      position: 'absolute',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    }}
                                                  >
                                                    <Typography variant="caption" component="div" color="text.secondary">
                                                      {`${Math.round(progress)}%`}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                        
                                          </Item>
                                                <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                                                <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: '#01adc7'}} href={`${ENDPOINTALLEGATI}${el.filename}`} download target="_blank" rel="noopener noreferrer">{el.filename}</a></Item>
                                                <Item key={index+3} sx={{boxShadow: 'none'}}><button type="button" className="btn btn-danger btn-sm" style={{float: 'right'}}
                                                onClick={() => eliminaAllegato(el.filename, index)}>Elimina sul server</button></Item>
                                            </Stack> 
                                            <Divider />
                                            </>
                                        ))}
                                          
                                        
                                        
                                        </Item>
                                    </Grid>
                                  
                                 </Grid>
                  
                    </>
                    : null }

    
      <Stack sx={{ width: '100%', paddingBottom: '8px', marginTop: '25px'}} spacing={8}>
      <Alert variant="outlined" severity="info">
      {`2) VERIFICA I DOCUMENTI ALLEGATI NELLA ZONA ANTEPRIMA, SE NON CORRETTI CLICCA SUL CESTINO PER ELIMINARLI.\
       DOPO, SE VUOI PUOI AGGIUNGERNE ALTRI`}
      </Alert>
      </Stack>
                 
       <Alert variant="outlined"  severity="info" sx={{marginBottom: '5px'}}>{`3) CLICCA SUL BOTTONE "ALLEGA DOCUMENTI ALLA PRATICA" PER TERMINARE L'OPERAZIONE DI CARICAMENTO DEGLI ALLEGATI`}</Alert>

      <Typography component={'div'} sx={{padding: '10px', marginBottom: '5px'}} >
      <Button variant="contained" startIcon={<CloudUploadIcon />} onClick={()=>handleAllegato()}>Allega documenti alla pratica</Button></Typography>

      <Alert variant="outlined" severity="warning" sx={{padding: '10px', marginBottom: '5px'}}>
      Se DOPO aver Allegato documenti alla pratica si ha un ripensamento e si aggiungono o si eliminano documenti,
      nell'area Anteprima, cliccare nuovamente il bottone "ALLEGA DOCUMENTI ALLA PRATICA"! 
      Prima di inviare la pratica, accertarti che i documenti nell'ara anteprima corrispondono 
      con quelli caricati sul server (evidenziati dalla riga blu)</Alert>


      <form  onSubmit={handleSubmit}>
      <Stack sx={{ width: '100%', paddingBottom: '8px', marginTop: '25px'}} spacing={8}>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" name="note" label="Note" variant="outlined" fullWidth
      defaultValue={note}
      onChange={(event)=>setNote(event.target.value)}
      />

    </Box>
      </Stack>


      {renderBottoni()}
      </form>
      </Container>

      



        </>


    );
}

    return (
        <TableBozzeContenitore data={data} columns={columns} renderRowSubComponent={renderRowSubComponent} praticaSelezionata={praticaSelezionata} deletePratica={deletePratica} isLoading={isLoading} 
        isError={isError} isRefetching={isRefetching} setColumnFiltersFunc={setColumnFiltersFunc} setGlobalFilterFunc={setGlobalFilterFunc} setSortingFunc={setSortingFunc} setPaginationFunc={setPaginationFunc} rowCount={rowCount} handleExportRows={handleExportRows} handleExportData={handleExportData}/>


    );
}

export default BozzeSub;