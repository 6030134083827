import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const LandingHome = () => {
const  navigate = useNavigate()
  return (
    <>
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="sm">
       
    <div>Landing Home page </div>
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" startIcon={<DeleteIcon />} onClick={()=> navigate("/signin") }>
        Login
      </Button>

    </Stack>
    </Container>
  </React.Fragment>


    </>
  )
}

export default LandingHome