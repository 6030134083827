import React, {useState} from 'react'
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
//import Link from '@mui/material/Link';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBar from './MenuBar';
import Divider from '@mui/material/Divider';
import PowerIcon from '@mui/icons-material/Power';
import ExtensionIcon from '@mui/icons-material/Extension';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TopicIcon from '@mui/icons-material/Topic';
import { styled } from '@mui/system';

// Styled BottomNavigationAction
const TabBarButton = styled(BottomNavigationAction)({

  '&.Mui-selected': {
    color: 'secondary',
  },
});

function getNumber(location) {
  const pathname = location.split("/")[2];
  console.log("admin pathname", pathname);
  if (!pathname) return 0;
  else {
    switch (pathname) {
     case "pratiche":
        return 0
      case "utenti":
        return 1
      case "moduli":
        return 2
      case "gestione":
        return 3
      case "dashboard":
        return 4

      default:
        return 0;
    }
  }
}


const HomeAdmin = () => {

  const location = useLocation();
 
  const navigate = useNavigate();
  const [value, setValue] = useState(getNumber(location?.pathname));
  return (
    <Stack gap={"1px"}>
    <MenuBar />    
        <Box
          sx={{  padding: "20px", }}
        >
        {/* 
        {value}
        <Typography variant="h6" align={"center"}>
          Bentornato Admin
        </Typography>
*/}
        <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction 
        label="Pratiche" 
        icon={<TopicIcon/>} 
        onClick={() => navigate("/admin/pratiche")}
        />
        <BottomNavigationAction 
        label="Utenti" 
        icon={<ManageAccountsIcon />} 
        onClick={() => navigate("/admin/utenti")}
        />
        <BottomNavigationAction 
        label="Moduli" 
        icon={<ExtensionIcon />} 
        onClick={() => navigate("/admin/moduli")}
        />
        <BottomNavigationAction 
        label="Gestione" 
        icon={<PowerIcon />} 
        onClick={() => navigate("/admin/gestione")}
        />
       
        <BottomNavigationAction 
        label="Dashboard" 
        icon={<DashboardIcon />} 
        onClick={() => navigate("/admin/dashboard")}
        />
       
        </BottomNavigation>



   {/*      <Link
          component="button"
          variant="body2"
          onClick={() => navigate("/")}
        >
        HomePage
        </Link>

        <Link
          component="button"
          variant="body2"
          onClick={() => navigate("/files")}
        >
        files page
      </Link> */}
      </Box>
      <Divider />
      <Outlet />
    </Stack>
  );
};

export default HomeAdmin;

