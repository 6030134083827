import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Logo from '../Logo-patronato.png'
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountUser from './AccountUser';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
/* import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7'; */
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

import {
  Tooltip,
} from "@mui/material";

import { useContext, useMemo } from "react";
import { ThemeContext } from '../ThemeContextProvider'; 

export default function MenuBar() {

  const navigate = useNavigate();

  const theme = useTheme();
    const { switchColorMode } = useContext(ThemeContext);
    const activateName = useMemo(
      () => (theme.palette.mode === "dark" ? "Light" : "Dark"),
      [theme]
    );


  const [anchorStop, setAnchorStop] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(true);

  const isMenuStopOpen = Boolean(anchorStop);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleStopServerMenuOpen = (event) => {
    setAnchorStop(event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorStop(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const logout = () => {

let items = ["currentUser"];

items.forEach(items => {
    localStorage.removeItem(items);
})
 navigate('/signin');
}

const menuStopId = 'primary-search-account-menu';
  const renderMenuStop = (
    <Menu
      anchorEl={anchorStop}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuStopId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuStopOpen}
      onClose={handleMenuClose}
    >
      <MenuItem >
      <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    /></MenuItem>
      <MenuItem >{checked ? "Server ON" : "Server OFF"}</MenuItem>

    </Menu>
  );

  const menuId = 'primary-search-account-menu';
  
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );



  const mobileMenuId = 'primary-search-account-menu-mobile';
  
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem > 
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>

      <MenuItem onClick={switchColorMode}>
      <Tooltip title={`Activate ${activateName} Mode`}>
            <IconButton
              
              sx={{
                p: 1,
                //border: `1px ${theme.palette.text.disabled} solid`,
              }}
              size="small"
              color="inherit"
            >
              {theme.palette.mode === "dark" ? (
                <LightModeIcon />
              ) : (
                <DarkModeIcon color="action" />
              )}
            </IconButton>
          </Tooltip>
          <p>Dark Mode</p>
        </MenuItem>


    </Menu>
  );

  return (
<>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='secondary'>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
           
          </IconButton>
          <Typography
            variant="h1"
            noWrap
            component="div"
          //  sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <img src={Logo} alt="" style={{width: '50%'}} />
          </Typography>
   
          <Box sx={{ flexGrow: 1 }} />

          


          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton
              size="large"
              edge="end"
              aria-label="server stop of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleStopServerMenuOpen}
              color="inherit"
            >
              <AppSettingsAltIcon />
            </IconButton>

            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Tooltip title={`Activate ${activateName} Mode`}>
            <IconButton
              onClick={switchColorMode}
              sx={{
                p: 1,
                //border: `1px ${theme.palette.text.disabled} solid`,
              }}
              size="small"
              color="inherit"
            >
              {theme.palette.mode === "dark" ? (
                <LightModeIcon />
              ) : (
                <DarkModeIcon color="action" />
              )}
            </IconButton>
          </Tooltip>
        </Box>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderMenuStop}
    </Box>
    </>

  );
}