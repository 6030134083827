import React, {useState, useEffect} from 'react'
import { Navigate} from "react-router-dom";
import Endpoints from '../../../Component/services/Endpoints';
//import logo from '../../logo.svg';
import axios from "axios";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const ENDPOINT = Endpoints.getUrls();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const MyItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


 const AssociaModuli = () => {

    const [data, setData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [pensioni, setPensioni] = useState([]);
    const [disoccupazioniMobilita, setDisoccupazioniMobilita] = useState([]);
    const [permessiCongedi, setPermessiCongedi] = useState([]);
    const [maternita, setMaternita]= useState([]);
    const [ratei, setRatei] = useState([]);
    const [assegniNucleoFamiliare, setAssegniNucleoFamiliare]= useState([]);
    const [ricostituzioni, setRicostituzioni] = useState([]);
    const [sostegnoAlReddito, setSostegnoAlReddito] = useState([]);
    const [invaliditaConvocazioneVistita, setInvaliditaConvocazioneVistita] = useState([]);
    const [invaliditaFaseConcessoria, setInvaliditaFaseConcessoria] = useState([]);
    const [certificazioni, setCertificazioni]= useState([]);
    const [altrePratiche, setAltrePratiche] = useState([]);
    const [versamentiVolontari, setVersamentiVolontari] = useState([]);
    const [emergenzaCovid, setEmergenzaCovid] = useState([]);
    
    const [moduliList, setModuliList]= useState([]);

    useEffect(() => {
      const doFetch = async () => {
      setSpinnerLoading(true);
          const response = await fetch(`${ENDPOINT}admin/moduli/`);
          const moduli = await response.json();
          setModuliList(moduli);
      setSpinnerLoading(false);
          };
          doFetch();
      }, []);

    
      useEffect(() => {
        const doFetch = async () => {
          setSpinnerLoading(true);
              const response = await fetch(`${ENDPOINT}tipopratica/`);
              const paratiche = await response.json();
  
                console.log(paratiche);
                  let pensioniArr = []
                  for(let i=0; i< paratiche.length; i++){
                      // if(paratiche[i].tipo_pratica === 'pensioni'){pensioniArr.push(paratiche[i])}
                      if(paratiche[i].ordine === 0 ){pensioniArr.push(paratiche[i])}
                  }
                  setPensioni(pensioniArr);
  
                  let disoccupazioniMobArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'disoccupazioni e mobilità'){disoccupazioniMobArray.push(paratiche[i])}
                  }
                  setDisoccupazioniMobilita(disoccupazioniMobArray);
  
  
                  let permessiCongediArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'permessi e congedi'){permessiCongediArray.push(paratiche[i])}
                  }
                  setPermessiCongedi(permessiCongediArray);
  
                  let maternitaArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'maternità'){maternitaArray.push(paratiche[i])}
                  }
                  setMaternita(maternitaArray);
  
  
                  let rateiArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'ratei'){
                        rateiArray.push(paratiche[i])}
                  }
                  setRatei(rateiArray);
  
                  let assegniNucleoFamArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'assegni nucleo familiare'){assegniNucleoFamArray.push(paratiche[i])}
                  }
                  setAssegniNucleoFamiliare(assegniNucleoFamArray);
  
                  let ricostituzioniArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'ricostituzioni'){ricostituzioniArray.push(paratiche[i])}
                  }
                  setRicostituzioni(ricostituzioniArray);
  
                  let sostegnoAlRedditoArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'sostegno al reddito'){sostegnoAlRedditoArray.push(paratiche[i])}
                  }
                  setSostegnoAlReddito(sostegnoAlRedditoArray);
  
                  let invaliditaConvocazioneVistitaArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'invalidità - per convocazione a visita'){invaliditaConvocazioneVistitaArray.push(paratiche[i])}
                  }
                  setInvaliditaConvocazioneVistita(invaliditaConvocazioneVistitaArray);        
                  
                  let invaliditaFaseConcessoriaArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'invalidità - fase concessoria AP70'){invaliditaFaseConcessoriaArray.push(paratiche[i])}
                  }
                  setInvaliditaFaseConcessoria(invaliditaFaseConcessoriaArray);        
  
                  let certificazioniArray = [];
                  for(let i=0; i< paratiche.length; i++){
                     // if(paratiche[i].tipo_pratica === 'certificazioni'){certificazioniArray.push(paratiche[i])}
                      if(paratiche[i].ordine === 12){certificazioniArray.push(paratiche[i])}
                  }
                  setCertificazioni(certificazioniArray); 
  
                  let altrePraticheArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'altre pratiche'){altrePraticheArray.push(paratiche[i])}
                  }
                  setAltrePratiche(altrePraticheArray); 
  
                  let versamentiVolontariArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'versamenti volontari'){versamentiVolontariArray.push(paratiche[i])}
                  }
                  setVersamentiVolontari(versamentiVolontariArray); 
  
                  let emergenzaCovidArray = [];
                  for(let i=0; i< paratiche.length; i++){
                      if(paratiche[i].tipo_pratica === 'emergenza covid-19'){emergenzaCovidArray.push(paratiche[i])}
                  }
                  setEmergenzaCovid(emergenzaCovidArray); 
                  
  
                setData(paratiche);
          setSpinnerLoading(false);
              };
              doFetch();
        }, []);

        const handleSubmit = (event) =>{
          event.preventDefault();
          console.log("hello")


          let moduloId =[];
        for(let option of event.target[0]){
          if(option.selected){
            console.log(option.value);
            moduloId.push(option.value);
          }
        }

        const selectedArr = [];
        for(let i=1; i<14; i++){

          for(let option of event.target[i]){
            if(option.selected){
            
              selectedArr.push(option.value);
            }
          }
          console.log(JSON.stringify(selectedArr));
        }
        
        for(let x=0; x<selectedArr.length; x++){

          axios.post(`${ENDPOINT}admin/moduli/associapratica/id?_id=${selectedArr[x]}`, {
            moduli: moduloId
          })
          .then(function (response) {
            alert(response.data.success);
           // fetchData();
            window.location.reload();

          })
          .catch(function (error) {
            alert(error);
          });

        }

    }



  return (

    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="lg" sx={{marginTop: '6rem'}}>
    <Box sx={{ minWidth: 820 }}>
    <Divider>Seleziona il modulo da associare ad una o più pratiche.</Divider>
    <FormControl fullWidth>


    <InputLabel id="demo-simple-select-label">Moduli</InputLabel>
    <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{fontSize: 'smaller'}}
    //   defaultValue={moduliList[0]._id}
        defaultValue={moduliList.length >0 ? moduliList[0]._id: ''}
    >

    {
      moduliList.length > 0 && (
        moduliList?.map(val =>
        <MenuItem 
        key={val._id} 
        value={val._id} 
        style={{padding: '0.4rem', fontSize: 'small'}}>{val.tipo}</MenuItem>
        )
      )
    }
    </Select>
    </FormControl>

    <FormControl fullWidth sx={{marginTop: '3rem'}}>
      <Grid container spacing={4}>
        <Grid item xs={4} md={4}>
        <Item>
          <FormControl sx={{ m: 2, minWidth: 300 }}>
          <InputLabel shrink htmlFor="select-multiple-native">
            Pensioni
          </InputLabel>
          
          <Select
            multiple
            native
            sx={{fontSize: 'smaller'}}
          //  value={pensioni}
           // onChange={handleChangeMultiple}
            label="Pensioni"
            name='pensioni'
            inputProps={{
              id: 'select-multiple-native',
            }}
          >
            {pensioni.map((val) => (
              <option key={val._id} value={val._id}>
                {val.descrizione}
              </option>
            ))}
          </Select>
          
        </FormControl>
        </Item>
        </Grid>

        <Grid item xs={8} md={8}>
        <Item>
            <FormControl sx={{ m: 2, minWidth: 300 }}>
            <InputLabel shrink htmlFor="select-multiple-native">
            Disoccupazioni e Mobilità
            </InputLabel>

            <Select
            multiple
            native
            sx={{fontSize: 'smaller'}}
            //  value={pensioni}
            // onChange={handleChangeMultiple}
            label="Pensioni"
            name='pensioni'
            inputProps={{
                id: 'select-multiple-native',
            }}
            >
            {disoccupazioniMobilita.map((val) => (
                <option key={val._id} value={val._id}>
                {val.descrizione}
                </option>
            ))}
            </Select>
    
            </FormControl>
        </Item>
        </Grid>


      </Grid>
    
    </FormControl>

    <FormControl fullWidth sx={{marginTop: '3rem'}}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
        <Item>
          <FormControl sx={{ m: 2, minWidth: 300 }}>
          <InputLabel shrink htmlFor="select-multiple-native">
          permessiCongedi
          </InputLabel>
          
          <Select
            multiple
            native
            sx={{fontSize: 'smaller'}}
          //  value={pensioni}
           // onChange={handleChangeMultiple}
            label="permessiCongedi"
            name='permessiCongedi'
            inputProps={{
              id: 'select-multiple-native',
            }}
          >
            {permessiCongedi.map((val) => (
              <option key={val._id} value={val._id}>
                {val.descrizione}
              </option>
            ))}
          </Select>
          
        </FormControl>
        </Item>
        </Grid>

        <Grid item xs={12} md={4}>
        <Item>
            <FormControl sx={{ m: 2, minWidth: 300 }}>
            <InputLabel shrink htmlFor="select-multiple-native">
            maternita
            </InputLabel>

            <Select
            multiple
            native
            sx={{fontSize: 'smaller'}}
            //  value={pensioni}
            // onChange={handleChangeMultiple}
            label="maternita"
            name='maternita'
            inputProps={{
                id: 'select-multiple-native',
            }}
            >
            {maternita.map((val) => (
                <option key={val._id} value={val._id}>
                {val.descrizione}
                </option>
            ))}
            </Select>
    
            </FormControl>
        </Item>
        </Grid>

        <Grid item xs={12} md={4}>
        <Item>
            <FormControl sx={{ m: 2, minWidth: 300 }}>
            <InputLabel shrink htmlFor="select-multiple-native">
            Ratei
            </InputLabel>

            <Select
            multiple
            native
            sx={{fontSize: 'smaller'}}
            //  value={pensioni}
            // onChange={handleChangeMultiple}
            label="Ratei"
            name='Ratei'
            inputProps={{
                id: 'select-multiple-native',
            }}
            >
            {ratei.map((val) => (
                <option key={val._id} value={val._id}>
                {val.descrizione}
                </option>
            ))}
            </Select>
    
            </FormControl>
        </Item>
        </Grid>

      </Grid>
    
    </FormControl>


    <FormControl fullWidth sx={{marginTop: '3rem'}}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
      <Item>
        <FormControl sx={{ m: 2, minWidth: 300 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
        assegniNucleoFamiliare
        </InputLabel>
        
        <Select
          multiple
          native
          sx={{fontSize: 'smaller'}}
        //  value={pensioni}
         // onChange={handleChangeMultiple}
          label="assegniNucleoFamiliare"
          name='assegniNucleoFamiliare'
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {assegniNucleoFamiliare.map((val) => (
            <option key={val._id} value={val._id}>
              {val.descrizione}
            </option>
          ))}
        </Select>
        
      </FormControl>
      </Item>
      </Grid>

      <Grid item xs={12} md={4}>
      <Item>
          <FormControl sx={{ m: 2, minWidth: 300 }}>
          <InputLabel shrink htmlFor="select-multiple-native">
          ricostituzioni
          </InputLabel>

          <Select
          multiple
          native
          sx={{fontSize: 'smaller'}}
          //  value={pensioni}
          // onChange={handleChangeMultiple}
          label="ricostituzioni"
          name='ricostituzioni'
          inputProps={{
              id: 'select-multiple-native',
          }}
          >
          {ricostituzioni.map((val) => (
              <option key={val._id} value={val._id}>
              {val.descrizione}
              </option>
          ))}
          </Select>
  
          </FormControl>
      </Item>
      </Grid>

      <Grid item xs={12} md={4}>
      <Item>
          <FormControl sx={{ m: 2, minWidth: 300 }}>
          <InputLabel shrink htmlFor="select-multiple-native">
          sostegnoAlReddito
          </InputLabel>

          <Select
          multiple
          native
          sx={{fontSize: 'smaller'}}
          //  value={pensioni}
          // onChange={handleChangeMultiple}
          label="sostegnoAlReddito"
          name='sostegnoAlReddito'
          inputProps={{
              id: 'select-multiple-native',
          }}
          >
          {sostegnoAlReddito.map((val) => (
              <option key={val._id} value={val._id}>
              {val.descrizione}
              </option>
          ))}
          </Select>
  
          </FormControl>
      </Item>
      </Grid>

    </Grid>
  
  </FormControl>



  <FormControl fullWidth sx={{marginTop: '3rem'}}>
  <Grid container spacing={4}>
    <Grid item xs={12} md={4}>
    <Item>
      <FormControl sx={{ m: 2, minWidth: 300 }}>
      <InputLabel shrink htmlFor="select-multiple-native">
      certificazioni
      </InputLabel>
      
      <Select
        multiple
        native
        sx={{fontSize: 'smaller'}}
      //  value={pensioni}
       // onChange={handleChangeMultiple}
        label="certificazioni"
        name='certificazioni'
        inputProps={{
          id: 'select-multiple-native',
        }}
        
      >
        {certificazioni.map((val) => (
          <option key={val._id} value={val._id}>
            {val.descrizione}
          </option>
        ))}
      </Select>
      
    </FormControl>
    </Item>
    </Grid>

    <Grid item xs={12} md={4}>
    <Item>
        <FormControl sx={{ m: 2, minWidth: 300 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
       Altre Pratiche
        </InputLabel>

        <Select
        multiple
        native
        sx={{fontSize: 'smaller'}}
        //  value={pensioni}
        // onChange={handleChangeMultiple}
        label="altrePratiche"
        name='altrePratiche'
        inputProps={{
            id: 'select-multiple-native',
        }}
        >
        {altrePratiche.map((val) => (
            <option key={val._id} value={val._id}>
            {val.descrizione}
            </option>
        ))}
        </Select>

        </FormControl>
    </Item>
    </Grid>

    <Grid item xs={12} md={4}>
    <Item>
        <FormControl sx={{ m: 2, minWidth: 300 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
        versamentiVolontari
        </InputLabel>

        <Select
        multiple
        native
        sx={{fontSize: 'smaller'}}
        //  value={pensioni}
        // onChange={handleChangeMultiple}
        label="versamentiVolontari"
        name='versamentiVolontari'
        inputProps={{
            id: 'select-multiple-native',
        }}
        >
        {versamentiVolontari.map((val) => (
            <option key={val._id} value={val._id}>
            {val.descrizione}
            </option>
        ))}
        </Select>

        </FormControl>
    </Item>
    </Grid>

    <Grid item xs={12} md={4}>
    <Item>
        <FormControl sx={{ m: 2, minWidth: 300 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
        emergenzaCovid
        </InputLabel>

        <Select
        multiple
        native
        sx={{fontSize: 'smaller'}}
        //  value={pensioni}
        // onChange={handleChangeMultiple}
        label="emergenzaCovid"
        name='emergenzaCovid'
        inputProps={{
            id: 'select-multiple-native',
        }}
        >
        {emergenzaCovid.map((val) => (
            <option key={val._id} value={val._id}>
            {val.descrizione}
            </option>
        ))}
        </Select>

        </FormControl>
    </Item>
    </Grid>

  </Grid>

</FormControl>
  </Box>
    </Container>
  </React.Fragment>

   
  )
}
export default AssociaModuli