import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';

const Card = styled(MuiCard)(({ theme }) => ({
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'light'
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
  boxShadow: 'none',
  background:
    theme.palette.mode === 'light'
      ? 'hsl(210, 100%, 98%)'
      : 'hsla(210, 100%, 15%, 0.25)',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[900]
      : theme.palette.grey[200],
}));

export default function HighlightedCard() {

  const userDetail = JSON.parse(localStorage.getItem('currentUser'));

  function useAuthRoleAdmin() {
    if(localStorage.getItem('currentUser')){
   //   console.log("TEST-RoleCONS: ", localStorage.getItem('currentUser'));
      const tokens = JSON.parse(localStorage.getItem('currentUser'));
        if(tokens.token && (tokens.role[0] === "ROLE_ADMIN")){
          return true;
        }
    }
    return false;
  }


  




  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <InsightsRoundedIcon color="primary" />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: '600' }}
        >
        <Stack direction="row" spacing={2}>
        <Avatar sx={{ bgcolor: deepPurple[500] }}>{`${userDetail.cognome[0]} ${userDetail.nome[0]}`}</Avatar>
      </Stack>
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
          Bentornato {`${userDetail.nome} ${userDetail.cognome}`}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          
        >
         Ruolo: {useAuthRoleAdmin() ? "Admin" : "Utente"}
        </Button>
      </CardContent>
    </Card>
  );
}
