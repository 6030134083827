import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import Endpoints from '../../../Component/services/Endpoints';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CiuseSubRows from './CiuseSubRows'
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'; //note: this is TanStack React Query V5

const ENDPOINT = Endpoints.getUrls();

const ChiuseSub = () => {
  //manage our own state for stuff we want to pass to the API
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //consider storing this code in a custom hook (i.e useFetchUsers)
  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
   // data,
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(`${ENDPOINT}pratiche/remotechiuse`,
        process.env.NODE_ENV === 'production'
          ? 'https://www.material-react-table.com'
          : 'http://localhost:3000',
      );

      //read our state and pass it to the API as query params
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex * pagination.pageSize}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(TransfomFilterData() ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const TransfomFilterData = () => {
    let bio = columnFilters;
    let array = [];
    for(var i =0; i<bio.length; i++){

  let filterQuery = '';

  console.log(bio[i].id.toString()) //access the object property 
  console.log(bio[i].value.toString())
  let a = bio[i].id.toString();
  let b = bio[i].value.toString();
    console.log(a, b)
    if (a === 'pratica_numero') {
      filterQuery = { statoPratica : "Chiusa", [a] : parseInt(b)};
    } else
    filterQuery = { statoPratica : "Chiusa", [a] : {'$regex' : '^' + b, '$options' : 'i'}};
  console.log(filterQuery)
  array.push(filterQuery)
  }
 console.log(array); 
//Object.keys(bio).forEach(key => delete object[key]);
    return array
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
     //   enableHiding: false,
        header: '_id',
       
      },
      {
        accessorKey: 'pratica_numero',
        header: 'id',
        minSize: 20, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 20, //medium column
      },
      //column definitions...
      {
        accessorKey: 'descrizione',
        header: 'Descrizione',
      },
      {
        accessorKey: 'assistito.regione',
        header: 'Regione',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
      {
        accessorKey: 'assistito.cognome',
        header: 'Assistito',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
 /*  */
      {
        //accessorFn function that combines multiple data together
      accessorFn: (row) => (row.assistito.codice_fiscale_assistito ? row.assistito.codice_fiscale_assistito : undefined),
      id: 'assistito.codice_fiscale_assistito',
      header: 'CF.Assistito',
      minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },

      {
        //accessorFn function that combines multiple data together
      accessorFn: (row) => `${row.consulente.nome} ${row.consulente.cognome}`,
      id: 'consulente.cognome',
      header: 'Consulente',
      },
/*
      {
        accessorFn: (row) => `${row.statoPratica} `,
        id: 'statoPratica',
        header: 'Stato',
      }, */
      {
        accessorFn: (row) => `${row.data_scadenza} `,
        id: 'data_scadenza',
        header: 'Scade il',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
      
            /*
      {
        accessorKey: d => `${d.storico_pratica[d.storico_pratica.length - 1].data}`,
        header: 'Inviata il',
      },
      */
      //end
    ],
    [],
);

  const table = useMaterialReactTable({
    columns,
    data,
    defaultColumn : { minSize: 20, maxSize: 1000, size: 180 }, //units are in px
    paginationDisplayMode: 'pages',
       initialState: { 
        showColumnFilters: true,
        columnVisibility: { _id: false } //hide _id column by default
       // pagination: { pageSize: 25, pageIndex: 2 }
    },
    enableColumnFilterModes: true,
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
  //  manualSorting: true, //turn off built-in client-side sorting
 
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: meta?.totalRowCount ?? 0,
    state: {
    columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    renderDetailPanel: ({ row }) => <CiuseSubRows row={row}/>,
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
    }),
    renderTopToolbarCustomActions: () => (
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      ),
  });

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const Chiuse = () => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <QueryClientProvider client={queryClient}>
    <ChiuseSub />
  </QueryClientProvider>
);

export default Chiuse;
