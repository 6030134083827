import {
    StyledEngineProvider,
    ThemeProvider,
    createTheme,
  } from "@mui/material/styles";
  import { createContext, useMemo, useState } from "react";

  
  export const ThemeContext = createContext({
    switchColorMode: () => {},
  });
  
  export function ThemeContextProvider({ children }) {

    const [mode, setMode] = useState('dark');
  
    function switchColorMode() {
          setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      }
  
    const theme = useMemo(
      () =>
        createTheme({
          palette: {
            mode,
          },
        }),
      [mode]
    );
  
    return (
      <StyledEngineProvider injectFirst>
        <ThemeContext.Provider value={{ switchColorMode }}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
      </StyledEngineProvider>
    );
  }