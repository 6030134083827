import React, {useState, useEffect, useMemo, useCallback} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import { MuiFileInput } from 'mui-file-input'
import socketIOClient from "socket.io-client";
import FileUpload from '../../../File_Upload_Component/file-upload.component';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Endpoints from '../../../Component/services/Endpoints';
import { styled } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableContainerModuli from './TableContainerModuli';
import FormMessageDialog from './FormMessageDialog';
import { useForm } from "react-hook-form"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text,
}));

const ENDPOINT = Endpoints.getUrls();

const ModuliAdminSub = ({row}) => {

    const [data, setData] = useState([])
 
    const [NewStatoPratica, setNewStatoPratica] = useState('');
    const [isClosedShow, setIsClosedShow] = useState(false);
    const [isNotClosedShow, setIsNotClosedShow] = useState(false);
    const [showUploader, setShowUploader] = useState(false);
   
    const [selectedFiles, setSelectedFiles] = useState([]);

    //FileInfos anche se da warning che non è usato non è vero
    const [fileInfos, setFileInfos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [ricevuta, setRicevuta] = useState([]);

    const [notex, setNotex] = useState('');
    const [selectedIdPratica, setSelectedIdPratica] = useState('');

    const [isVisible, setIsVisible] = useState(true);
    const [newRicevutaInfo, setNewRicevutaInfo] = useState({
        ricevutaFile: []
    });

    const [file, setFile] =  useState([]);
    const [newFileName, setNewFileName] =  useState('');
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm()

    const onSubmit = (data) => console.log(data)
    console.log(watch("tipo")) // watch input value by passing the name of it
    console.log(watch("contenuto"))
    console.log(watch("messaggio"))
   // console.log(watch("file"))

    const handleChange = (newFile) => {
      if (newFile) {
        if (newFile.type !== "application/pdf") {
          alert("Solo file PDF sono accettati");
          return
        }
        setFile(newFile);
        UploadFile(newFile);
      } else {
        setFile([]);
       // UploadFile(newFile);
      }
    
    }

    const UploadFile = (files) => {

      if (files) {
        
      let filex = [files]
      const dataDoc = new FormData()
      for(var x = 0; x<filex.length; x++) {
          dataDoc.append('docCollection', filex[x]);
       //   data.append('name', 'ricevitabis');
  
      }
  
     const headers = {
      "Content-Type": "multipart/form-data",
      }
  
      axios.post(`${ENDPOINT}documenti`, dataDoc, {
      headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: ProgressEvent => {
        setProgress((ProgressEvent.loaded / ProgressEvent.total*100))
    },
    })
  
    .then(response => { // then print response status
      console.log(response);
      setFileInfos(response.data);
  
        let documentoArray = [];
  
         for(let i =0; i< response.data.length; i++){
          documentoArray.push(
            response.data[i].filename
           )
         }
         setNewFileName(response.data.filename)
        // setDocumentoFile(documentoArray);
    })
  }
}
    
     //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //from old component
    const [currentLoggedUser, setCurrentLoggedUser] = useState({});
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const [openDialog, setOpenDialog] = useState(false);
  //  const [operatoreAttivo, setOperatoreAttivo] = useState(false);

    const operatoreAttivo = JSON.parse(localStorage.getItem('currentUser'));
  // const {_id, cognome, nome, email, role} = operatoreClient;
  //   setOperatoreAttivo(operatoreClient);

  function setColumnFiltersFunc(val){
    setColumnFilters(val);
   }
   function setGlobalFilterFunc(val){
    setGlobalFilter(val);
   }
   function setSortingFunc(val){
    setSorting(val);
   }

   function setPaginationFunc(val){
    setPagination(val);
   }

  // fetch pratiche in lavorazione by operatore id
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
       
           const fetchData = async () => {
             if (!data.length) {
               setIsLoading(true);
             } else {
               setIsRefetching(true);
             }
           
           const op = JSON.parse(localStorage.getItem('currentUser'));
           const id = op._id;
           setCurrentLoggedUser(op);
       
            const url = new URL(`${ENDPOINT}admin/moduli/`,
               process.env.NODE_ENV === 'production'
                 ? 'https://www.material-react-table.com'
                 : 'http://localhost:3000',
             );
             url.searchParams.set(
               'start',
               `${pagination.pageIndex * pagination.pageSize}`,
             );
             url.searchParams.set('size', `${pagination.pageSize}`);
             url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
             url.searchParams.set('globalFilter', globalFilter ?? '');
             url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
       
             try {
               const response = await fetch(url.href);
               const json = await response.json();
               setData(json);
               setRowCount(response.headers.get('X-Total-Count'));
             } catch (error) {
               setIsError(true);
               console.error(error);
               return;
             }
             setIsError(false);
             setIsLoading(false);
             setIsRefetching(false);
           };
           fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [
           columnFilters,
           globalFilter,
           pagination.pageIndex,
           pagination.pageSize,
           sorting,
  ]);


const renewTableAfterDelete = (val) => {
  setData((data) => data.filter(({_id}) => _id !== val.praticaId));

}

const renewTable = (val) =>{       
  setData((data) => data.filter(({_id}) => _id !== val));  
}


function praticaSelezionata( praticaID ) {
setSelectedIdPratica(praticaID)
console.log("praticaSelezionata", praticaID)
}
        
function deletePratica( row ) {
   
  var idPratica = row._id;
  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

    if(adminClient.role[0] === 'ROLE_ADMIN'){
      Swal.fire({
        title: `Vuoi davvero eliminare la pratica n° ${row.pratica_numero}?`,
        icon: 'danger',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, elimina!',
        footer: `<b>Admin<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
    //inizio conferma seleziona
      // qui elimino la pratica
        fetch(`${ENDPOINT}pratiche/eliminazione/action/id?_id=${idPratica}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        
        })
      .then(res => res.json())
      .then(data => {
        if(data.success){

          let updTbl = {
            praticaId: idPratica
          }
       
          renewTableAfterDelete(updTbl);

          Swal.fire(
            'Eliminata!',
            'Un documento eliminato con successo.',
           // data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}
}



const columns = useMemo(
           () => [
             {
               accessorKey: '_id',
               header: '_id',
             },
             {
              accessorKey: 'tipo',
              header: 'Tipo',
            },
             //column definitions...
             {
               accessorKey: 'messaggio',
               header: 'Messaggio',
             },
             {
               accessorKey: 'documento',
               header: 'Documento',
             },
             {
                accessorKey: 'contenuto',
                header: 'Contenuto',
              },
           ],
           [],
);
    

    const renderRowSubComponent = (row) => {
 //   console.log(row.original)

    let {
        _id,
        tipo,
        messaggio,
        documento,
        ...rest
    } = row.original;
    let contenuto = rest.contenuto;


    const renewTable = (val) => {
            setData((data) => data.filter(({_id}) => _id !== val.praticaId));

    }



    const eliminaRiceuta = (path, index) => {

    alert(path, index);
    console.log(ricevuta);
    console.log(fileInfos)

    setRicevuta([
        ...ricevuta.slice(0, index),
        ...ricevuta.slice(index + 1)
    ]);

    setFileInfos([
        ...fileInfos.slice(0, index),
        ...fileInfos.slice(index + 1)
    ]);

    axios({
        method: 'get',
        url: `${ENDPOINT}pratica/delete/ricevuta/path?path=${path}`
    })
        .then(function (response) {
            console.log(response);
        });
    }

    /* NUOVO UPLOADER */
    const handleFiles = () => {
            setIsVisible(true);
            let selectedFile = newRicevutaInfo.ricevutaFile;

            console.log('Test Files');

            const data = new FormData()
            for (var x = 0; x < selectedFile.length; x++) {
                data.append('rcvCollection', selectedFile[x]);
            }
            const headers = {"Content-Type": "multipart/form-data"};

            // ATTENZIONE A DOVE CARICA
            axios.post(`${ENDPOINT}ricevute`, data, {
                headers: headers,
                // receive two    parameter endpoint url ,form data
                onUploadProgress: ProgressEvent => {
                    setProgress((ProgressEvent.loaded / ProgressEvent.total * 100))
                },
            }).then(response => { // then print response status
                console.log(response);
                setFileInfos(response.data);

                let ricevuteArray = [];

                for (let i = 0; i < response.data.length; i++) {
                    ricevuteArray.push({
                        path: response.data[i].filename,
                        creato: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
                    })
                }

                setRicevuta(ricevuteArray);

            })
            //********************* */
    }



    const RenderDocumento = () => {
        return (

            <div>
        {documento?.map((rowData, index) => ( 

            <Stack key={index}
                direction="row"
                sx={{ width: '100%' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={12}
            >
                <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: 'darkorange'}} href={`${ENDPOINT}static/allegati/${rowData.path}`} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></Item>
                <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</Item>
            </Stack>   
            
        ))}
        </div>          
        )
    }

function RedBar() {
    return (
      <Box
        sx={{
          height: 20,
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? 'rgb(229 229 229)'
              : 'rgb(52, 48, 51)',
        }}
      />
    );
  }

    const RenderModuloBlock = () => {

        return(
            <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
            <TextField 
            fullWidth 
            value={tipo}
            label="tipo" 
            id="tipo" 
            name='tipo'
            {...register("tipo")}
            />
        <RedBar />
            <TextField 
            fullWidth 
            value={messaggio}
            label="messaggio" 
            id="messaggio"
            name="messaggio"
            {...register("messaggio")}
            />
            <RedBar />
              
            <TextField 
            fullWidth 
            value={documento}
            label="documento attuale" 
            id="documento" 
            name='documento'
            {...register("documento")}
            />
            <RedBar />
            <TextField 
            fullWidth 
            value={contenuto}
            label="contenuto" 
            id="contenuto" 
            name='contenuto'
            {...register("contenuto")}
            />
            <RedBar />
            <MuiFileInput value={file} onChange={handleChange}  placeholder="Insert a file" fullWidth
            clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="small" />
              }}
              inputProps={{ accept: '.pdf' }}
            //  getSizeText={(value) => 'Very big'}
           // {...register("file")}
            />
            <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <input type="submit" />
          </form>
            </Container>
        )
    }


    return (
        <>
        <Container maxWidth="lg">
        {RenderModuloBlock()}
        </Container>




        </>


    );
}

    return (
        <TableContainerModuli 
        data={data} 
        columns={columns} 
        renderRowSubComponent={renderRowSubComponent} 
        isLoading={isLoading} 
        isError={isError}
        renewTable={renewTable} 
        praticaSelezionata={praticaSelezionata} 
        deletePratica={deletePratica} 
        isRefetching={isRefetching} 
        setColumnFiltersFunc={setColumnFiltersFunc} 
        setGlobalFilterFunc={setGlobalFilterFunc} 
        setSortingFunc={setSortingFunc} 
        setPaginationFunc={setPaginationFunc} 
        rowCount={rowCount}
        />


    );
}

export default ModuliAdminSub;