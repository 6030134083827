import React, {useState} from 'react'
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
//import Link from '@mui/material/Link';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import MessageIcon from '@mui/icons-material/Message';
import InventoryIcon from '@mui/icons-material/Inventory';
import MenuBar from './MenuBar';
import Divider from '@mui/material/Divider';


function getNumber(location) {
  const pathname = location.split("/")[2];
 // console.log(pathname);
  if (!pathname) return 0;
  else {
    switch (pathname) {
      case "home":
        return 0;
      case "pratiche":
        return 1
      case "messaggi":
        return 2
      case "archivio":
        return 3
      default:
        return 0;
    }
  }
}


const HomeConsulente = () => {

  const location = useLocation();
 
  const navigate = useNavigate();
  const [value, setValue] = useState(getNumber(location?.pathname));
  return (
    <Stack gap={"1px"}>

    <MenuBar />    

        <Box
          sx={{  padding: "20px", }}
        >
        {value}
        <Typography variant="h6" align={"center"}>
          Home Consulente
        </Typography>

        <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction 
        label="Home" 
        icon={<HomeIcon />} 
        onClick={() => navigate("/")}
        />
        <BottomNavigationAction 
        label="Pratiche" 
        icon={<FolderIcon />} 
        onClick={() => navigate("/consulente/pratiche")}
        />
        <BottomNavigationAction label="Messaggi" icon={<MessageIcon />} />
        <BottomNavigationAction label="Archivio" icon={<InventoryIcon />} />
       
      </BottomNavigation>

   {/*      <Link
          component="button"
          variant="body2"
          onClick={() => navigate("/")}
        >
        HomePage
        </Link>

        <Link
          component="button"
          variant="body2"
          onClick={() => navigate("/files")}
        >
        files page
      </Link> */}
      </Box>
      <Divider />
      <Outlet />
    </Stack>
  );
};

export default HomeConsulente;

