import * as React from 'react';
import { useState, useFetch } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";

import DoneIcon from '@mui/icons-material/Done';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import {
  IndiaFlag,
  UsaFlag,
  BrazilFlag,
  GlobeFlag,
} from '../internals/components/CustomIcons';



const valori = [
  { label: 'India', value: 50000 },
  { label: 'USA', value: 35000 },
  { label: 'Brazil', value: 10000 },
  { label: 'Other', value: 5000 },
];

const countries = [
  {
    name: 'India',
    value: 50,
    flag: <IndiaFlag />,
    color: 'hsl(220, 25%, 65%)',
  },
  {
    name: 'USA',
    value: 35,
    flag: <UsaFlag />,
    color: 'hsl(220, 25%, 45%)',
  },
  {
    name: 'Brazil',
    value: 10,
    flag: <BrazilFlag />,
    color: 'hsl(220, 25%, 30%)',
  },
  {
    name: 'Other',
    value: 5,
    flag: <GlobeFlag />,
    color: 'hsl(220, 25%, 20%)',
  },
];

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: theme.palette.text.secondary,
  fontSize:
    variant === 'primary'
      ? theme.typography.h5.fontSize
      : theme.typography.body2.fontSize,
  fontWeight:
    variant === 'primary'
      ? theme.typography.h5.fontWeight
      : theme.typography.body2.fontWeight,
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <React.Fragment>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </React.Fragment>
  );
}

PieCenterLabel.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};

const colors = [
  'hsl(220, 25%, 65%)',
  'hsl(220, 25%, 45%)',
  'hsl(220, 25%, 30%)',
  'hsl(220, 25%, 20%)',
];

function ChartByTipoPratica() {

const { data, error, isLoading } = useQuery({
  queryKey: ["groupandcount"],
  queryFn: () =>
    fetch('https://sgp.lambdamedia.it:5001/dashboard/dashtipopratitica').then((res) =>
      res.json()
    ),
},
);
console.log("tuttooo", data);
const result = useQuery({  queryKey: ['total'],
  queryFn: () =>
    fetch('https://sgp.lambdamedia.it:5001/dashboard/gettotal').then((res) =>
      res.json()
    ),
   })

   const totale = result?.data;

console.log("TOTALEEE", result.data);

if (error) {
  return <div> Was an Erroe</div>
}

if (isLoading) {
  return <div> Loading...</div>
}
  return (
   
    <Card variant="outlined" sx={{ pb: '8px' }}>

      <CardContent>
        <Typography component="h2" variant="subtitle2">
          Conteggio pratiche
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChart
          colors={['green', 'orange', 'red', 'violet', 'blue','yellow']} // Use palette
           // colors={colors[2]}
           /*
[
  {
    "_id": "Chiusa",
    "count": 9157
  },
  {
    "_id": "Annullata",
    "count": 698
  },
  {
    "_id": "Sospesa",
    "count": 353
  },
  {
    "_id": "Bozza",
    "count": 89
  },
  {
    "_id": "Lavorazione",
    "count": 42
  },
  {
    "_id": "inviata",
    "count": 32
  }
]
           */
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                data: [
                  {id: 0, value: data[0].count },
                  {id: 1, value: data[1].count, },
                  {id: 2, value: data[2].count},
                  {id: 3, value: data[3].count},
                  {id: 4, value: data[4].count},
                  {id: 5, value: data[5].count},
                
                ]
                /*
                [
                  {id: 0, value: 45 },
                  {id: 1, value: 80, },
                  {id: 2, value: 100},
                  {id: 3, value: 100},
                ]
                  */
                
                ,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={260}
            width={260}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel primaryText={totale[0].totale.toString()} secondaryText="Totale" />
          </PieChart>


        </Box>
        {data?.map((groupandcount, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: 'center', gap: 2, pb: 2 }} 


          >
            { groupandcount._id === 'inviata' ? <DoneIcon /> : groupandcount._id === 'Chiusa' ? <SportsScoreIcon /> :  groupandcount._id === 'Sospesa' ? <ScheduleSendIcon /> 
              :  groupandcount._id === 'Lavorazione' ? <ContentPasteSearchIcon /> :  groupandcount._id === 'Bozza' ? <EditNoteIcon /> :  groupandcount._id === 'Annullata' ? <FolderOffIcon /> : null } 
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                  {groupandcount._id}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {groupandcount.count}%
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                value={groupandcount.count}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: groupandcount.color,
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </Card>



  );
}

const queryClient = new QueryClient({})
export default function ChartUserByCountry(){

  return(

    <QueryClientProvider client={queryClient}>
    <ChartByTipoPratica />
    </QueryClientProvider>
  )

}