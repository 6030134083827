
class Endpoints {

  getUrls() {
   // return "https://staging.cdlservizipatronato.it:5001/";
    return "https://sgp.lambdamedia.it:5001/"

  }

  getAllegatiUrls() {

    return "https://sgp.lambdamedia.it:5001/";
  }

  getDocumentiUrls() {

    return "https://sgp.lambdamedia.it:5001/";
  }

  //da testare

  getRicevuteUrls() {

    return "https://sgp.lambdamedia.it:5001/";
  }


}

export default new Endpoints();
