import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useSearchParams, useNavigate} from "react-router-dom";
import Endpoints from "../../Component/services/Endpoints";
//import logo from '../../logo.svg';
import socketIOClient from "socket.io-client";
import axios from "axios";
import AllegatiUpload from '../Allegati_Upload_Component/Allegati-upload.component'

import Swal from 'sweetalert2';

import parse from 'html-react-parser'

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import './Suggestion.css';

import UploadService from "../../Component/services/upload-files.service";
//import UploadFiles from "./uploadAllegato";

const ENDPOINT = Endpoints.getUrls();
const ENDPOINTALLEGATI = Endpoints.getAllegatiUrls();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




const NuovaPratica = () => {

  //const location = useLocation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = React.useState(
    searchParams.get("ids")
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const [message, setMessage] = useState([]);
  const [allegati, setAllegati] = useState([]);
  const [tipoPratica, setTipoPratica] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [assistitoText, setAassistitoText] = useState("");
  const [assistitoLabel, setAssistitoLabel] = useState("");
  const [suggestAssistito, setSuggestAssistito] = useState([]);
  const [assistito, setAssistito] = useState([]);


  const [cliente, setCliente] = useState({
    _id:'',
    consulenteid: '',
    cognome: '', 
    nome: '',  
    email: '', 
    telefono:'', 
    cellulare:'', 
    indirizzo: '', 
    indirizzo_altro:'', 
    citta:'', 
    cap:'', 
    stato:'', 
    regione:'', 
    comune:'', 
    provincia:'', 
    categoria_assistito:'', 
    stato_civile:'', 
    data_stato_civile:'',
    codice_fiscale_assistito:''
   });
  const [clienteEsistente, setClienteEsistente] = useState({});
  //questa erve per la select stato_civile
  const [tipoStatoCivile, setTipoStatoCivile] = React.useState('');


  const [selectedFile, setSelectedFile] = useState([]);
  const [loaded, setLoaded] = useState(0);
  const [dataScadenza, setDataScadenza] = useState(new Date());
  const [dataStorico, setDataStorico] = useState(new Date()); 
  const [inviata, setInviata] = useState(new Date().toISOString());
  const [consulente, setConsulente] = useState({});
  const [consulenteLabel, setConsulenteLabel] = useState("");
  const [statoPratica, setStatoPratica] = useState('');
  const [collaboratoreNome, setCollaboratoreNome] = useState('');
  const [collaboratoreCognome, setCollaboratoreCognome] = useState('');
  const [collaboratoreCodiceFiscale, setCollaboratoreCodiceFiscale] = useState('');
  const [note, setNote] = useState('');
  const [validCf, setValidCf] = useState(false);
  const [dataStatoCivileShow, setDataStatoCivileShow] = useState(false);
  const [tipoPraticaIdSelected, setTipoPraticaIdSelected] = useState({});
  const [collaboratoreVolontario, setCollaboratoreVolontario] = useState({});
  const [redirectTo, setRedirectTo] = useState(false);
  const [moduloToBind, setModuloToBind] = useState([]);
  const [moduloBinded, setModuloBinded] = useState([]);
  const [docNecessariToBind, setDocNecessariToBind ] = useState([]);
  const [options, setOptions] = useState([]);
  //questo per elemimare gli allegati prima dell'invio?
  const [allegato, setAllegato]= useState([]);
  const [emptyAllegato, setEmptyAllegato] = useState(true);
  const [salvaAssistito, setSalvaAssistito] = useState(true);
  const [isActiveAccount , setIsActiveAccount] = useState("");

  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const [fileInfos, setFileInfos] = useState([]);
  const [tags, setTags] = useState([]);
  const [newAllegatoInfo, setNewAllegatoInfo] = useState({
    allegatoFile: []
});


   useEffect(() => {

    
    const fetchData = async () => {
      console.log(Endpoints.getUrls());
  
      let oPobject = JSON.parse(localStorage.getItem('currentUser'));
    
      const requestMenuItems = {
        method: 'POST',
        async: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ids: oPobject._id })
          };
    
        fetch(`${ENDPOINT}consulente/checkcollaboratore/`, requestMenuItems)
        .then(response => response.json())
        .then(data => setCollaboratoreVolontario(data.collaboratore));
        const request = {
          method: 'GET',
          async:true,
          headers: { 'Content-Type': 'application/json' }
            };
    
          fetch(`${ENDPOINT}users/checkstato/id?_id=${oPobject._id}`, request)
          .then(response => response.json())
          .then(data => setIsActiveAccount(data.stato)
          );
          createMenuTipoPratica();
          ceckLoggedUser();
    }
    
    fetchData()
  },[])
 


  const showFerieMessage = () =>{


    if(localStorage.hasOwnProperty("hideMessage")){
      console.log(localStorage.getItem("hideMessage"));
      let boolStatus = localStorage.getItem("hideMessage");
  
      if(boolStatus === "false"){
        Swal.fire({
          title: 'Si informa che il servizio non sarà disponibile, per la pausa estiva, dal 13/08 al 28/08 - Ringraziandovi per la gentile collaborazione vi auguriamo Buone Vacanze.',
          input: 'checkbox',
          inputPlaceholder: 'Non mostrare più questo messaggio'
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              localStorage.setItem("hideMessage", true)
              Swal.fire({icon: 'success', text: 'non vedrai questo messaggio fino al prossimo login'});
            } else {
              Swal.fire({icon: 'success', text: 'ti mostreremo ancora questo messaggio durante la sessione'});
              localStorage.setItem("hideMessage", false)
            }
          } else {
            console.log(`modal was dismissed by ${result.dismiss}`)
          }
        })
  
      }
  
     console.log("Item exists in localstorage");
  }else{
    Swal.fire({
      title: 'Si informa che il servizio non sarà disponibile, per la pausa estiva, dal 13/08 al 28/08 - Ringraziandovi per la gentile collaborazione vi auguriamo Buone Vacanze.',
      input: 'checkbox',
      inputPlaceholder: 'Non mostrare più questo messaggio'
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          localStorage.setItem("hideMessage", true)
          Swal.fire({icon: 'success', text: 'non vedrai questo messaggio fino al prossimo login'});
        } else {
          Swal.fire({icon: 'error', text: "You don't have a bike :("});
          localStorage.setItem("hideMessage", false)
        }
      } else {
        console.log(`modal was dismissed by ${result.dismiss}`)
      }
    })
     console.log("Item does not exist in localstoarge");
  }
  
  }
  
  
  const ceckLoggedUser = () => {
  
    if(localStorage.getItem('currentUser')){
      let oPobject = JSON.parse(localStorage.getItem('currentUser'));
      setConsulenteLabel(oPobject.cognome +' ' +oPobject.role[0]);
      setConsulente(oPobject);
  
    }
  
  }
  
  const createMenuTipoPratica = () => {
    //  const search = window.location.search;
    //  const params = new URLSearchParams(search);
    //  const foo = params.get('_id');
    //  console.log(foo);
  
   // let parameters =getId();
    setTipoPraticaIdSelected(query);
 // const objects = JSON.parse(window.localStorage.getItem('tipoPaticaId'));
  
   //   var url = `http://localhost:5001/users/autosearch/cognome?cognome=${svalue}`;
    //  fetch(`http://localhost:5001/tipopratica/_id/${foo}`, {
      fetch(`${ENDPOINT}tipopratica/_id/${query}`, {
        crossDodiv:true,
        method: 'GET',
        headers: {'Content-Type':'application/json'},
      })
      .then((response) => response.json())
      .then(response => {

        setTipoPratica(response[0].tipo_pratica);
        setDescrizione(response[0].descrizione);
        setModuloToBind(response[0].modulo);
        setDocNecessariToBind(response[0].documenti_necessari)

        //  createFilteredArray(response)
          console.log(response);
  
  
         // let self = this;
          axios.post(`${ENDPOINT}admin/moduli/bind/`, {
            ids: moduloToBind
          })
          .then((response) => {
            if(response) {
              setModuloBinded(response.data)
              console.log('response');
              console.log(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
  
      });//fine primo fetch
  
  }
  
  const handleChangeAssistito = (event) => {

    if(event.target.name === 'cognome'){
      setCliente((prevState) => ({
        ...prevState,
        cognome: event.target.value,
      }));
    }

      if(event.target.name === 'nome'){
        setCliente((prevState) => ({
          ...prevState,
          nome: event.target.value,
        }));
      }

        if(event.target.name === 'email'){
          setCliente((prevState) => ({
            ...prevState,
            email: event.target.value,
          }));
        }

          if(event.target.name === 'telefono'){
            setCliente((prevState) => ({
              ...prevState,
              telefono: event.target.value,
            }));
          }

            if(event.target.name === 'cellulare'){
              setCliente((prevState) => ({
                ...prevState,
                cellulare: event.target.value,
              })); 
            }

              if(event.target.name === 'indirizzo'){
                setCliente((prevState) => ({
                  ...prevState,
                  indirizzo: event.target.value,
                })); 
              }  

              if(event.target.name === 'citta'){
                setCliente((prevState) => ({
                  ...prevState,
                  citta: event.target.value,
                })); 
              }

                if(event.target.name === 'cap'){
                  setCliente((prevState) => ({
                    ...prevState,
                    cap: event.target.value,
                  })); 

                }

                  if(event.target.name === 'stato'){
                    setCliente((prevState) => ({
                      ...prevState,
                      stato: event.target.value,
                    })); 
                  }

                  if(event.target.name === 'regione'){
                    setCliente((prevState) => ({
                      ...prevState,
                      regione: event.target.value,
                    })); 
                  }

                  if(event.target.name === 'provincia'){
                    setCliente((prevState) => ({
                      ...prevState,
                      provincia: event.target.value.toUpperCase(),
                    })); 
                  }

                  if(event.target.name === 'comune'){
                    setCliente((prevState) => ({
                      ...prevState,
                      comune: event.target.value.toUpperCase(),
                    })); 
                  }
    
                  if(event.target.name === 'categoria_assistito'){
                    setCliente((prevState) => ({
                      ...prevState,
                      categoria_assistito: event.target.value,
                    }));
                  }

    if(event.target.name === 'stato_civile'){
      setCliente((prevState) => ({
        ...prevState,
        stato_civile: event.target.value,
      }));
    }

    if(event.target.name === 'codice_fiscale_assistito'){

      if(event.target.name === 'codice_fiscale_assistito'){
        setCliente((prevState) => ({
          ...prevState,
          codice_fiscale_assistito: event.target.value,
        }));
      
     let cf =  validaCodiceFiscale(event.target.value);
        if (cf === true){
          setValidCf(true);
          
        }
    }
    }

  
      if(event.target.value === 'nubile o celibe'){
        setDataStatoCivileShow(false);
      } else setDataStatoCivileShow(true);


      if(event.target.name === 'data_stato_civile'){
        setCliente((prevState) => ({
          ...prevState,
          data_stato_civile: event.target.value,
        }));
      }
      
  
  }

  const validaCodiceFiscale = (cf) =>
        {
          console.log(cf);
            var validi, i, s, set1, set2, setpari, setdisp;
            if( cf === '' )  return '';
            cf = cf.toUpperCase();
            if( cf.length !== 16 )
                return false;
            validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            for( i = 0; i < 16; i++ ){
                if( validi.indexOf( cf.charAt(i) ) === -1 )
                    return false;
            }
            set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
            setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
            s = 0;
            for( i = 1; i <= 13; i += 2 )
                s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
            for( i = 0; i <= 14; i += 2 )
                s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
            if( s%26 !== cf.charCodeAt(15)-'A'.charCodeAt(0) )
                return false;
            return true;
  }
  
  // nuovo combobox
   const handleInputChange = (event, value) => {
    console.log("// nuovo combobox");
    console.log(value);
    console.log(event.target.value);
   const inputValue = value;

  if (!inputValue) {
    setOptions([]);
    setClienteEsistente([]);
    setCliente({
    _id:'',
    consulenteid: '',
    cognome: '', 
    nome: '',  
    email: '', 
    telefono:'', 
    cellulare:'', 
    indirizzo: '', 
    indirizzo_altro:'', 
    citta:'', 
    cap:'', 
    stato:'', 
    regione:'', 
    comune:'', 
    provincia:'', 
    categoria_assistito:'', 
    stato_civile:'', 
    data_stato_civile:'',
    codice_fiscale_assistito:''
    })
  }
  console.log(inputValue)
  
      if (inputValue !== '') {  
      let url = `${ENDPOINT}assistiti/autosearch/cognome?cognome=${inputValue}&consulente=${consulente._id}`;
          fetch(url)
          .then((response) => response.json())
          .then(response => {
      
            setOptions(response);      
              console.log(response);
      
          });//fine primo fetch
      
      }
  }

  const onTagsChange = (event, values) => {
    console.log("Onchange")

    if(values){

      /*
{
    "_id": "62df9ea7566833be70beb89f",
    "consulenteid": "62c8023cb8fea4dcd84c4d71",
    "cognome": "Ranocchia",
    "nome": "Franco",
    "email": "MAVERICA70@YAHOO.IT",
    "telefono": "3393803003",
    "cellulare": "3466027570",
    "indirizzo": "Bla 96c",
    "indirizzo_altro": "",
    "citta": "ROMA",
    "cap": "00133",
    "stato": "",
    "regione": "",
    "comune": "Roma",
    "provincia": "",
    "categoria_assistito": "lavoro dipendente privato",
    "stato_civile": "coniugato",
    "data_stato_civile": "2000-01-01",
    "codice_fiscale_assistito": "MRTDNL67L51H501E"
}
      */

  setCliente(values);
  /*

      setCliente(prevState => ({

        cliente: {                   // object that we want to update
          ...prevState.cliente,    // keep all other key-value pairs
          values     // update the value of specific key
        }
          })
      );
*/

      // se ho selezionato un cliente esistente, devo mostrare la data dello stato civile
        if(values.stato_civile !== 'Nubile/Celibe'){
          setDataStatoCivileShow(true);
        }

      //qui cerco di impostare il cliente selzionato sotituendolo a quello controllato dalla form
    //  setCliente(values);
      let cf =  validaCodiceFiscale(values.codice_fiscale_assistito);
      if (cf === true){
        setValidCf(true);          
      }

    }


    setTags(values);
  }

  //Sezione Assistito
  
  const assistitoSelected = (data, value, value2, value3) => {
    console.log(value);
  
    if(data){
      setClienteEsistente(prevState => ({
  
        clienteEsistente: {                   // object that we want to update
          ...prevState.clienteEsistente,    // keep all other key-value pairs
          data     // update the value of specific key
        }
          }), () => {
            // se ho selezionato un cliente esistente, devo mostrare la data dello stato civile
            if(data.stato_civile !== 'Nubile/Celibe'){
              setDataStatoCivileShow(true);
              
            }
  
            console.log("New clienteEsistente in ASYNC callback:", clienteEsistente);
          }
      )
  
      //qui cerco di impostare il cliente selzionato sotituendolo a quello controllato dalla form
  
      setCliente(data);
      let cf =  validaCodiceFiscale(data.codice_fiscale_assistito);
      if (cf === true){
        setValidCf(true);
      }
  
    }
  
    setAssistitoLabel(`${value2} ${value3}`);
    suggestAssistito([]);
  
  }

  const renderAssistitoSuggs=()=>{
    const mapdata = suggestAssistito;
    if (mapdata.length === 0){
        return null;
    }
  
  return (
    <li style={{listStyle: 'none'}} >
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <PersonSearchIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item   sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {mapdata.map((part, index) => (
                    <Box
                      key={index}
  
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      onClick={() => assistitoSelected(part, part._id, part.cognome, part.nome)}
                    >
                      {part.cognome} {part.nome} {part.codice_fiscale_assistito}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {/* mapdata.structured_formatting.secondary_text */}
                  </Typography>
                </Grid>
              </Grid>
            </li>
  )
 
  }
  
  const renderModuli=()=>{
  
  /*
    function creaCodiceHtml(valore) {
      return {__html: valore};
    }
  */
    if(moduloBinded.length === 0){
      return null;
    }
  
    return(
      <React.Fragment>
  
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: false, paddingBottom: '8px' }} aria-label="simple table">
          <TableBody>
          {moduloBinded.map(({_id, tipo, messaggio, contenuto, documento }) => (
            <TableRow key={_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row"><Typography><strong>{tipo}</strong></Typography></TableCell>
                <TableCell align="right"><Button variant="contained" href={`${ENDPOINT}static/documenti/${documento}`} download target="_blank" rel="noopener noreferrer"> Scarica    </Button></TableCell>
                <TableCell align="right">{messaggio !== " " ?
                <Typography component={'span'} sx={{ width: '33%', flexShrink: 0 }}>Descrizione: {messaggio}</Typography>
                : null }</TableCell>
  
  
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  
  
   </React.Fragment>
    ) 
  }
  
  const renderDocumentiNecessari=()=>{
    return(
      <span  dangerouslySetInnerHTML={{ __html: docNecessariToBind }} ></span>
    ) 
  }
  
  const renderCollaboratoreVolontario = () => {
  // collaboratoreVolontario
  
      const collab2 = collaboratoreVolontario;
  
      return(
        <Box sx={{ flexGrow: 3 }}>
        <Grid container spacing={6}>
          <Grid item md={4}>
          <TextField fullWidth label="Nome" variant="filled"  id="nome_collaboratore" name="nome_collaboratore"  controlid="nome_collaboratore" value={collaboratoreVolontario?.nome ? collaboratoreVolontario.nome : ''} disabled />
     
          </Grid>
          <Grid item md={4}>
          <TextField fullWidth id="cognome_collaboratore" name="cognome_collaboratore" label="Cognome" variant="filled" value={collaboratoreVolontario?.cognome ? collaboratoreVolontario.cognome : ''} disabled/>

          </Grid>
          <Grid item md={4}>
          <TextField fullWidth id="codice_fiscale_collaboratore"  name="codice_fiscale_collaboratore" label="Codice Fiscale" variant="filled" value={collaboratoreVolontario?.codice_fiscale ? collaboratoreVolontario.codice_fiscale : ''} disabled/>
          </Grid>
  
        </Grid>
      </Box>
  
      )
  
  
  
  
  }
  
  const renderBottoni = () => {
  
    if(isActiveAccount === 'Off'){
  
      return <div style={{color: 'red', textAlign: 'center', padding: '20px', marginTop: '25px'}}><h4>Account sospeso: prego contattare CDL per l'abilitazione. Grazie</h4></div>
  
    } else {
  
      if(!collaboratoreVolontario.codice_fiscale){
        return( <div>Non puoi procedere: regolarizzare la posizione Del collaboratore volontario  </div> )
  
      } else if (collaboratoreVolontario.codice_fiscale && !emptyAllegato) {
        return(
        <div>
        <Button variant="contained" color="success" type="submit" name="Bozza" style={{ float: 'left' }}>
          SALVA BOZZA
        </Button>
        <Button variant="contained" color="primary" type="submit" name="inviata" style={{ float: 'right' }}>
        INVIA Pratica
        </Button>
          </div>
        )
      } else if (collaboratoreVolontario.codice_fiscale && emptyAllegato) {
        return(
          <Button variant="contained" color="success" type="submit" name="Bozza" style={{ float: 'left' }}>
          SALVA BOZZA
        </Button>
        )
        }
  
    }
  
  }
  
  const handleScadenza = (event) => {
      const value = event.target.value;
      alert(new Date(value).toISOString());
    //  const datascadenza = new Date(value);
    const datascadenza =  new Date(value).toISOString();
     // alert(datascadenza)
      setDataScadenza(datascadenza);
    }
  
  const handleCollaboratore = (event) => {
      const nome = event.target.name;
          if(nome === 'nome_collaboratore'){
            setCollaboratoreNome(event.target.value);
            
          }
          if(nome === 'cognome_collaboratore'){
            setCollaboratoreCognome(event.target.value);
            
          }
          if(nome === 'codice_fiscale_collaboratore'){
            setCollaboratoreCodiceFiscale(event.target.value);
          }
      console.log(event.target.name)
  }
  
  const eliminaAllegato = (path, index) => {

    alert(path, index);
    console.log(allegato);
    console.log(fileInfos)

    setAllegato([
        ...allegato.slice(0, index),
        ...allegato.slice(index + 1)
    ]);

    setFileInfos([
        ...fileInfos.slice(0, index),
        ...fileInfos.slice(index + 1)
    ]);

    axios({
        method: 'get',
        url: `${ENDPOINT}pratica/delete/allegato/path?path=${path}`
    })
        .then(function (response) {
          setProgress(0);
            console.log(response);
        });
    }
  
  const updateUploadedFiles = (files) => {
    setNewAllegatoInfo({...newAllegatoInfo, AllegatoFile: files});

  }

  const handleAllegato = () => {
    setIsVisible(true);
    let selectedFile = newAllegatoInfo.AllegatoFile;
    //console.log(element)
    

    console.log('Test Allegato');

    const data = new FormData()
    for (var x = 0; x < selectedFile.length; x++) {
      if(selectedFile[x].type !== 'application/pdf')
    { alert('PUOI ALLEGARE SOLTANTO FILE PDF! Prego rimuovere tutti i file non pdf e ripetere l\'operazione');
      return;
    } else   {
      console.log('Test Files');
      data.append('allCollection', selectedFile[x]);
    
    const headers = {"Content-Type": "multipart/form-data"};

    // ATTENZIONE A DOVE CARICA
    axios.post(`${ENDPOINT}allegati`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: ProgressEvent => {
            setProgress((ProgressEvent.loaded / ProgressEvent.total * 100))
        },
    }).then(response => { // then print response status
      console.log(response);
      setFileInfos(response.data)
     //  setFileInfos(response.data);

      let allegatiArray = [];

        for(let i =0; i< response.data.length; i++){
          allegatiArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString()
          })
        }
        setAllegati(allegatiArray);
        setEmptyAllegato(false);

    })
  }

    //********************* */
}
  }
  /*
  */
  // files


  
    function upload(idx, file) {
      let _progressInfos = [...progressInfos];
  
      UploadService.upload(file, (event) => {
        _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
        
        setProgressInfos(_progressInfos)
       })
        .then((response) => {
               console.log(response);
  
          setMessage((prev) => {
            let nextMessage = [...prev.message, "Uploaded the file successfully: " + response.data.path];
  
            return {
              message: nextMessage
            };
  
          });
    //   return `http://localhost:5001/static/allegati/${response.data.path}`;
          return UploadService.getFiles(response.data);
        })
        .then((files) => {
  
            setFileInfos(prevState => ({
              fileInfos: [...prevState.fileInfos, files]
            }))
            const event = new Date();
            setAllegati(prevState => ({
              allegati: [...prevState.allegati, {creato: event.toISOString(), path: files.path}]
            }), () => {
              console.log("New allegati in ASYNC callback:", allegati);
            })
            setEmptyAllegato(false);
              
        })
        .catch(() => {
          _progressInfos[idx].percentage = 0;
          setMessage((prev) => {
            let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
            return {
              progressInfos: _progressInfos,
              message: nextMessage
            };
          });
        });
    }
  
    // handleSubmit
  
    const handleSaveAssistito = (event) => {
  
          setSalvaAssistito(event.target.checked);
  
    }
  
    const handleSubmit  = (event) => {
    event.preventDefault();
      const stato = event.nativeEvent.submitter.name;
      setStatoPratica(stato);
      if(stato === 'inviata'){
  
        if(allegati.length >0){
          setEmptyAllegato(true);
        }
  
      // setStatoPratica(event.nativeEvent.submitter.name);
       handleSubmit2(stato);

      }

      if(stato === 'Bozza'){
  
            if(allegati.length >0){
              setEmptyAllegato(true);             
            }
            handleSubmit2(stato);

            /*
            setStatoPratica({
                statoPratica: event.nativeEvent.submitter.name
              },() => {
                handleSubmit2();
                
              });
*/

            }

    }
  
   const handleSubmit2  = (stato) => {

/*
    const creaDate = new Date();
    let giorno = creaDate.getDate();
    let mese = creaDate.getMonth()+1;
    let anno = creaDate.getFullYear();
    let dateBuild = `${anno}-${mese}-${giorno}Z`;
    const dateFinal = new Date(dateBuild).toISOString();
*/

    const creaDate = new Date();
    let giorno = creaDate.getDate();
    let mese = creaDate.getMonth()+1;
    let anno = creaDate.getFullYear();
	  let ora = creaDate.getHours();
    let minuti = creaDate.getMinutes();
	  let secondi = creaDate.getSeconds();
  //  console.log(ora); // (January gives 0)
  //  console.log(minuti); // (January gives 0)
    let dateBuild = `${anno}-${mese}-${giorno}-${ora}:${minuti}:${secondi}Z`;
    const dateFinal = new Date(dateBuild).toISOString();



    let praticaObject = {
      consulente: consulente, 
      operatore: null,
      statoPratica: stato,
      allegato: allegati,
      tipo_pratica: tipoPratica,
      descrizione: descrizione,
      assistito: cliente,
      colloboratore: {
        nome: collaboratoreVolontario.nome,
        cognome: collaboratoreVolontario.cognome,
        codice_fiscale: collaboratoreVolontario.codice_fiscale
      },
      data_scadenza: new Date(dataScadenza).toISOString(),
      note: note,
      storico_pratica: [{
        stato: 'inviata',
        data: dateFinal}],
      ricevute: [],
      storico_note: [{
        nota: note,
        data: dateFinal}],
        creato: dateFinal,
    }
  
  
  
    console.log(praticaObject);
  
    async function postData(url = '', data = {}) {
      // Default MenuItems are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  
    postData(`${ENDPOINT}pratiche/create`, praticaObject)
      .then(data => {
     
        console.log(data); // JSON data parsed by `data.json()` call
  
        let creazioneObj = {
          consulente: praticaObject.consulente,
          tipo_pratica: praticaObject.tipo_pratica
        }
  
        const socket = socketIOClient(ENDPOINT);
        console.log(socket);
        socket.emit('creazione', JSON.stringify(creazioneObj));
  
        socket.on('creazione',function(payload){
        console.log('pratica trasmessa: ' , JSON.stringify(payload));
        if (stato === 'inviata') {
          setTimeout(() => {
          //  window.location.replace(`elenco_pratiche`);
            navigate("/consulente", {replace: false});
          }, "4000");
        }

        if (stato === 'Bozza') {
          setTimeout(() => {
            navigate("/consulente");
          //  window.location.replace(`bozze`);
          }, "4000");
        }
      });

  //    setRedirectTo(true);
  
      salvaUtenteNuovo(praticaObject);

      Swal.fire(
        'Pratica creata con successo! Reindirizzo...',
        data.success,
        'success'
      )




      });
  
    }
  
  const  salvaUtenteNuovo = (praticaObject) => {
    
    
    if(salvaAssistito){  


      let salvaClienteObj = cliente;
        let salvaCliente = {
        consulenteid: praticaObject.consulente._id,
        cognome: praticaObject.assistito.cognome,
        nome: praticaObject.assistito.nome,
        email: praticaObject.assistito.email,
        telefono: praticaObject.assistito.telefono,
        cellulare: praticaObject.assistito.cellulare,
        indirizzo: praticaObject.assistito.indirizzo,
        indirizzo_altro:praticaObject.assistito.indirizzo_altro,
        citta: praticaObject.assistito.citta,
        cap: praticaObject.assistito.cap,
        stato: praticaObject.assistito.stato,
        regione: praticaObject.assistito.regione,
        comune: praticaObject.assistito.comune,
        provincia: praticaObject.assistito.provincia,
        categoria_assistito: praticaObject.assistito.categoria_assistito,
        stato_civile: praticaObject.assistito.stato_civile,
        data_stato_civile: new Date(praticaObject.assistito.data_stato_civile).toISOString(),
        codice_fiscale_assistito: praticaObject.assistito.codice_fiscale_assistito
        }
   
      axios.post(`${ENDPOINT}assistiti/salva`, salvaClienteObj)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    }
  
   


    return (

      <>
      <CssBaseline />
      <Container >
    {/* searchParams ? JSON.stringify(searchParams) : null */}
    <form  onSubmit={handleSubmit} >


      <Box component={'div'}>{isActiveAccount === 'Off' ?
      <div style={{color: 'red', textAlign: 'center', padding: '20px', marginTop: '25px'}}><h4>Account sospeso: prego contattare CDL per l'abilitazione. Grazie</h4></div>
      : null
      }
      </Box>

      <Stack sx={{ width: '100%', paddingBottom: '8px' }} spacing={8}>
      <Alert variant="outlined" severity="info">
      <AlertTitle>Tipo pratica: { tipoPratica}</AlertTitle>
      <Typography >{' Descrizione: '}<strong>{ descrizione}</strong></Typography>
      </Alert>
      </Stack>

      <Stack sx={{ width: '100%', paddingBottom: '8px' }} spacing={8}>
      <Alert variant="outlined" severity="info">
      <AlertTitle><strong> Attenzione! </strong>Per la disamina dei documenti caricati possono essere impiegati fino ad un massimo di 7 giorni...</AlertTitle>
      </Alert>
      </Stack>

      <Stack sx={{ width: '100%', paddingBottom: '8px' }} spacing={8}>
      <Alert variant="outlined"  severity="warning">
      <AlertTitle>{renderDocumentiNecessari()}</AlertTitle>
      </Alert>
      </Stack>

  
      {renderModuli()}

      <Divider className={"container"} component="div" role="presentation"  sx={{marginTop: '20px', marginBottom: '20px'}}>
        <Typography variant="h5">Anagrafica assisitito</Typography>
      </Divider>

      <Typography component={'span'}  sx={{margin: '10px'}}>Cerca in anagrafiche esistenti <KeyboardArrowDownIcon /></Typography>
 
      <Autocomplete
      id="combo-box-demo"
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={options.cognome === 'string' ? options.cognome : options.cognome}
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.cognome
      }
      style={{ width: 300 }}
      onInputChange={handleInputChange}
      onChange={onTagsChange}
      renderInput={params => (
        <TextField {...params} label="Assistiti" variant="outlined" fullWidth />
      )}
    />

    <Box
    component="div"
    sx={{
      '& > :not(style)': { m: 1, width: '40ch' },
    }}
    noValidate
    autoComplete="off"
  >

  {renderAssistitoSuggs()}

    <TextField className="textfield"
    id="cognome"
    name="cognome"
    label="Cognome"
    variant="standard"
    value={cliente.cognome}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="nome"
    name="nome"
    label="Nome"
    variant="standard"
    value={cliente?.nome}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="email"
    name="email"
    label="Email"
    variant="standard"
    type="email"
    value={cliente?.email}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="telefono"
    label="Telefono"
    name="telefono"
    variant="standard"
    value={cliente?.telefono}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="cellulare"
    name="cellulare"
    label="Cellulare"
    variant="standard"
    value={cliente?.cellulare}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="indirizzo"
    name="indirizzo"
    label="Indirizzo"
    variant="standard"
    value={cliente?.indirizzo}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="citta"
    name="citta"
    label="Città"
    variant="standard"
    value={cliente?.citta}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="cap"
    name="cap"
    label="CAP"
    variant="standard"
    value={cliente?.cap}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="stato"
    name="stato"
    label="Stato"
    variant="standard"

    value={cliente?.stato}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="regione"
    name="regione"
    label="Regione"
    variant="standard"

    value={cliente?.regione}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="provincia"
    name="provincia"
    label="Provincia (AA)"
    variant="standard"
    value={cliente?.provincia}
    onChange={handleChangeAssistito}
    required
    />
    <TextField className="textfield"
    id="comune"
    name="comune"
    label="Comune"
    variant="standard"

    value={cliente.comune}
    onChange={handleChangeAssistito}
    required
    />
  <FormControl fullWidth>
    <InputLabel id="categoriaAssistito">categoriaAssistito</InputLabel>
    <Select
      labelid="categoria"
      id="categoria_assistito"
      name="categoria_assistito"
      value={cliente?.categoria_assistito}
      onChange={handleChangeAssistito}
      required
      label="Age"
    >
    <MenuItem value="">Selezionare...</MenuItem>
    <MenuItem value="artigiano">Artigiano</MenuItem>
    <MenuItem value="autonomo">Commerciante</MenuItem>
    <MenuItem value="commerciante">Autonomo</MenuItem>
    <MenuItem value="disoccupato">Disoccupato</MenuItem>
    <MenuItem value="gestione separata">Gestione separata</MenuItem>
    <MenuItem value="lavoro dipendente privato">Lavoro dipendente - privato</MenuItem>
    <MenuItem value="lavoro dipendente pubblico">Lavoro dipendente - pubblico</MenuItem>
    <MenuItem value="pensionato">Pensionato</MenuItem>
    <MenuItem value="studente minore">Studente/Minore</MenuItem>

    </Select>
  </FormControl>

  <FormControl fullWidth>
    <InputLabel id="stato_civile">Stato civile</InputLabel>
    <Select
      labelid="Stato civile"
      id="stato_civile"
      name="stato_civile"
      //value={(clienteEsistente?.stato_civile ? clienteEsistente.stato_civile : cliente.stato_civile )}
      value={cliente?.stato_civile}
      onChange={handleChangeAssistito}
      required
      label="Age"
    >
    <MenuItem value="">Selezionare...</MenuItem>
    <MenuItem value="nubile o celibe">Nubile/Celibe</MenuItem>
    <MenuItem value="coniugato">Coniugato</MenuItem>
    <MenuItem value="divorziato">Divorziato</MenuItem>
    <MenuItem value="separato">Separato</MenuItem>
    <MenuItem value="vedovo">Vedovo</MenuItem>

    </Select>
  </FormControl>

  

  { dataStatoCivileShow  ?
    <FormControl>
    <TextField className="textfield"
    id="statoCivile"
    controlid="dataStatoCivile"
    name="data_stato_civile"
    variant="standard"
    type="date"
    value={cliente?.data_stato_civile}
    required
    onChange={handleChangeAssistito}

    />
    </FormControl>
    : null }

    {/* cliente ? JSON.stringify(cliente) : null */}

    <FormControl>
    <TextField className={!validCf ? 'textfield red' : 'textfield green'}
    id="formGridCfAssistito"
    controlid="formGridCfAssistito"
    name="codice_fiscale_assistito"
    variant="standard"
    labelid="AAABBB00L00H000U"
    value={cliente.codice_fiscale_assistito}
    onChange={handleChangeAssistito}
    onInput={handleChangeAssistito}
    required
    />
    {!validCf ?
      <span style={{ color: 'red', fontWeigth: '700' }}> Codice fiscale errato <i className="bi bi-exclamation-triangle-fill"></i></span>
      : <span style={{ color: 'green', fontWeigth: '700' }}> Codice fiscale Corretto <i className="bi bi-check-square"></i> </span>}
    </FormControl>

    <FormGroup>
    <FormControlLabel
    control={<Switch defaultChecked color="warning" />}
    id="custom-switch"
    label="Salva/modifica Assistito"
    onChange={handleSaveAssistito}
    />

  </FormGroup>

  </Box>

  <Divider className={"container"} component="div" role="presentation"  
  sx={{marginTop: '20px', marginBottom: '20px'}}>

    <Typography variant="h5">Collaboratore volontario:</Typography>
    </Divider>
    
    <div className={"container"}>
    <strong>{renderCollaboratoreVolontario()}</strong>
    </div>

    <Divider className={"container"} component="div" role="presentation"  sx={{marginTop: '20px', marginBottom: '20px'}}>
   
      <Typography variant="h5">Data di Scadenza:</Typography>
    </Divider>
 
  
   

  <TextField
    id="statoCivile"
    controlid="formGridDataScadenza"
    name="data_stato_civile"
    variant="standard"
    type="date"
  //  min={new Date().toISOString().split('T')[0]}
    min={new Date().toISOString()}
    required
    onChange={handleScadenza}
  />


<Divider className={"container"} component="div" role="presentation"  sx={{marginTop: '20px', marginBottom: '20px'}}>

<Typography variant="h5">Allegati</Typography>
</Divider>

 

  <Stack sx={{ width: '100%',paddingBottom: '8px' }} spacing={2}>
  <Alert variant="outlined" severity="info">
  {`1) SELEZIONA I DOCUMENTI IN PDF DA ALLEGARE ALLA PRATICA CLICCANDO SU "SELEZIONA FILES`}
  </Alert>

</Stack>

<Stack sx={{ width: '100%',paddingBottom: '8px' }} spacing={2}>
  <AllegatiUpload
         
      accept=".pdf"
      label="Area caricamento ricevute"
      multiple
      updateFilesCb={updateUploadedFiles}
      />
      </Stack>
                {isVisible ?
                  <>
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  {progress >0 ?
                  <CircularProgress variant="determinate" value={progress} />
                : null 
                }
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                  {progress >0 ?
                    <Typography variant="caption" component="div" color="text.secondary">
                      {`${progress}%`}
                    </Typography>
                    : null}
                  </Box>
                </Box>
                  
                <Paper elevation={0} />
                        <div >
                          
                                {fileInfos.map((el, index) => {
                                    return (
                                    <>
                                    <Stack key={index}
                                        direction="row"
                                        sx={{ width: '100%' }}
                                        divider={<Divider  flexItem />}
                                        spacing={2}
                                    >
                                        <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                                        <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: 'orange'}}href={`${ENDPOINTALLEGATI}${el.filename}` }
                                         target="_blank" rel="noopener noreferrer" downlod="true">{el.filename}</a>
                                         </Item>
                                        <Item key={index+3} sx={{boxShadow: 'none'}}><button type="button" className="btn btn-danger btn-sm"
                                        style={{float: 'right'}}
                                        onClick={() => eliminaAllegato(el.filename, index)}>Elimina sul server</button></Item>
                                       
                                    </Stack> 
                                    <Divider key={index+3}/>
                                    </>
                                    )

                                })}                
                        
                                </div>

                        {newAllegatoInfo.allegatoFile && newAllegatoInfo.allegatoFile.length >0 ?
                        <Stack spacing={2} style={{boxShadow: 'none'}}>
                        <Item  style={{boxShadow: 'none'}}>
                        <Button 
                        sx={{float:'rigth'}}
                        onClick={handleAllegato}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        >Carica i documenti sul server remoto!</Button></Item>
                        </Stack>
                      : null }

                      <Stack sx={{ width: '100%', paddingBottom: '8px' }} spacing={8}>
                      <Alert variant="outlined" severity="info">
                      {`2) VERIFICA I DOCUMENTI ALLEGATI NELLA ZONA ANTEPRIMA, SE NON CORRETTI CLICCA SUL CESTINO PER ELIMINARLI.\
                       DOPO, SE VUOI PUOI AGGIUNGERNE ALTRI`}
                      </Alert>
                      </Stack>
 
                    <Paper />
                    </>
                    : null}
                   
 

 
       <Alert variant="outlined"  severity="info" sx={{marginBottom: '5px'}}>{`3) CLICCA SUL BOTTONE "ALLEGA DOCUMENTI ALLA PRATICA" PER TERMINARE L'OPERAZIONE DI CARICAMENTO DEGLI ALLEGATI`}</Alert>

      <Typography component={'div'} sx={{padding: '10px', marginBottom: '5px'}} >
      <Button variant="contained" onClick={()=>handleAllegato()}>Allega documenti alla pratica</Button></Typography>

      <Alert severity="warning" sx={{padding: '10px', marginBottom: '5px'}}>ATTENZIONE!
      Se DOPO aver Allegato documenti alla pratica (si ha un ripensamento) e si aggiungono o si eliminano documenti nell'area Anteprima:
      É necessario cliccare nuovamente sul bottone "ALLEGA DOCUMENTI ALLA PRATICA"!
      Quindi, se hai modificato i documenti, prima di inviare la pratica, accertarti che i documenti nell'anteprima corrispondano con quelli caricati sul server (evidenziati dalla riga blu)</Alert>

      <Alert severity="info" sx={{padding: '10px', marginBottom: '5px'}}>{`4) AGGIUNGI UNA NOTA NEL CAMPO NOTE`}</Alert>

      <Box
      sx={{
        width: 1,
        maxWidth: '100%',
      }}
    >
    <TextField 
    sx={{width: '100%'}}
    name='note'
    onChange={e => setNote(e.target.value)}
    value={note}
    id="outlined-basic" label="Aggiungi Note" variant="outlined" />

    </Box>

    <Alert severity="info" sx={{padding: '10px', marginBottom: '5px', marginTop: '5px'}}>{`5) CLICCA SU "SALVA BOZZA, SE VUOI TERMINARLA IN SEGUITO" O CLICCA SU "INVIA" PER INVIARE LA PRATICA`}</Alert>
    
    <div  fontSize="inherit"  severity="success">
    {renderBottoni()}
  </div>
    

      <Typography>{emptyAllegato}</Typography>
    
        <Container fluid="md" style={{ 'marginBottom': '50px' }}>

      {isActiveAccount === 'Off' ?
        <div style={{color: 'red', textAlign: 'center', padding: '20px', marginTop: '25px'}}><h4>Account sospeso: prego contattare CDL per l'abilitazione. Grazie</h4></div>
      : null
      }
        </Container>
    
</form>

  </Container>    
        </>
  )
}

export default NuovaPratica

