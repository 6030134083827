import React, { useState, useEffect, useMemo } from 'react';
import TipoPraticheTableHeader from './TipoPraticheTableHeader';
import Endpoints from '../../../Component/services/Endpoints';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';

const ENDPOINT = Endpoints.getUrls();

export const VediTipoPratiche = () => {
    
    const [data, setData] = useState([]);

    useEffect(() => {
        const doFetch = async () => {
        
            const response = await fetch(`${ENDPOINT}admin/tipopratica/moduli/join/`);
            const tipopratica = await response.json();
              console.log(tipopratica);
              setData(tipopratica);           
            };
            doFetch();
        }, []);

  // refetch after insert or update

  function handleRefetch(){

    const doReFetch = async () => {
        
      const resp = await fetch(`${ENDPOINT}admin/tipopratica/moduli/join/`);
      const tipopraticaR = await resp.json();
        console.log(tipopraticaR);
       // setData([...data, tipopraticaR]); 
        setData(tipopraticaR);            
      };
      doReFetch();
 }
 


  return (
    <TipoPraticheTableHeader  data={data} handleRefetch={handleRefetch} />
  )
}
