import React, { useRef, useState } from "react";
import {
  DragDropText,
} from './file_upload_styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 7500000;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));

const Input = styled('input')({
  display: 'none',
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const AllegatiUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = (event) => {
    event.stopPropagation();
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>

    <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
   
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<GetAppIcon />}
    >
    Seleziona i files sul computer
      <VisuallyHiddenInput type="file" onChange={handleNewFileUpload} />
    </Button>

  </Box>

  
  <DragDropText >Trascina e rilascia qui i tuoi files, oppure clicca per selezionarne uno o più dal tuo computer</DragDropText>  
  

 <div>
 <Box  sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
 <Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
/>
<Stack
direction="row"
divider={<Divider orientation="vertical" flexItem />}
spacing={4}
sx={{
  my: 1,
  mx: 'auto',
  p: 2,
}}
>
 {Object.keys(files).map((fileName, index) => {
   let file = files[fileName];
 //  let isImageFile = file.type.split("/")[0] === "image";
   return (
  
     <Item
     key={index}
       sx={{
         my: 1,
         mx: 'auto',
         p: 2,
       }}
     >
       <Stack key={index+1} spacing={2} direction="row" alignItems="center">         
         <Typography noWrap>{file.name}</Typography>
         <DeleteForeverIcon sx={{cursor:'pointer'}}
         onClick={() => removeFile(fileName)}
       />
       </Stack>
     </Item>
    
   );

 })}
 </Stack>
 </Box>
 </div>
</>
  );
};

export default AllegatiUpload;
